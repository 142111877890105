import { useState, useEffect, useRef } from "react";
import { currencyToStr, getTransactionAccountName } from "@utils";
import { useTranslation } from "react-i18next";
import { FaFileInvoice, FaCashRegister, FaMoneyBill } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AllTransactions = ({ transactions }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLast = (index) => index === transactions.length - 1;
  const [isTableScrollable, setIsTableScrollable] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!tableRef.current) return;

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      if (documentHeight - (scrollTop + windowHeight) < 20) {
        setIsTableScrollable(true);
      } else {
        setIsTableScrollable(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div ref={tableRef} className={`flow-root min-w-full align-middle max-h-[80vh] ${isTableScrollable ? "overflow-y-auto" : "overflow-hidden"}`}>
      <table className="min-w-full border-separate border-spacing-0">
        <thead className="z-10 bg-white h-10 sticky top-0">
          <tr>
            <th scope="col" className="w-24 whitespace-nowrap px-2 py-1 text-left text-xs font-semibold text-gray-500 uppercase bg-white border-b">
              {t("date")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-b">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-b">
              {t("account")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-b">
              {t("category")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-b">
              {t("proof")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-b">
              {t("amount")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 text-left text-xs font-bold text-gray-500 uppercase bg-white border-b">
              {t("status")}
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={transaction._id} className={`text-xs border-y border-gray-200 ${index % 2 === 1 ? "bg-white hover:bg-blue-100" : "bg-gray-100 hover:bg-blue-100"}`}>
              <td className={`whitespace-nowrap px-2 py-2   ${isLast(index) ? "border-b rounded-bl-lg" : ""} `}>
                <div>{transaction.date.slice(0, 10)}</div>
              </td>
              <td className={`whitespace-nowrap px-2 py-2 ${isLast(index) ? "border-b" : ""} `}>
                <div
                  className="cursor-pointer underline font-bold"
                  onClick={() => {
                    navigate(`/transactions?id=${transaction._id}`);
                  }}>
                  {(transaction.name || transaction.description || transaction.additionalInformation)?.length > 50
                    ? (transaction.name || transaction.description || transaction.additionalInformation).substring(0, 47) + "..."
                    : transaction.name || transaction.description || transaction.additionalInformation}
                </div>
              </td>

              <td className={`flex items-center gap-1 whitespace-nowrap pl-2 pr-5 py-2 ${isLast(index) ? "border-b" : ""} `}>
                <TransactionIcon transaction={transaction} />
                {getTransactionAccountName(transaction)}
              </td>

              <td className={`whitespace-nowrap w-[200px] px-2 py-2 ${isLast(index) ? "border-b" : ""} `}>
                {transaction.category ? transaction.category + " - " + t(`transactions.${transaction.category}`) : null}
              </td>

              <div className={`flex ${isLast(index) ? "border-b" : ""} `}>
                <td className="whitespace-nowrap  px-2 py-2 flex gap-2 m-auto mx-0">
                  {transaction.PurchaseInvoices.length > 0 && (
                    <>
                      {transaction.PurchaseInvoices.map((invoice) => (
                        <div key={invoice} className="  flex justify-center">
                          <a href={invoice.file} target="_blank" rel="noopener noreferrer" key={invoice._id} title={invoice.fileName}>
                            <FaFileInvoice size={17} />
                            <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{invoice.reference}</span>
                          </a>
                        </div>
                      ))}
                    </>
                  )}
                  {transaction.Invoices.length > 0 && (
                    <>
                      {transaction.Invoices.map((invoice) => (
                        <div key={invoice} className="flex justify-center">
                          <a href={invoice.file} target="_blank" rel="noopener noreferrer" key={invoice._id} title={invoice.fileName}>
                            <FaFileInvoice size={17} />
                            <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{invoice.reference}</span>
                          </a>
                        </div>
                      ))}
                    </>
                  )}
                </td>
              </div>

              <td className={`whitespace-nowrap px-2 py-2 ${isLast(index) ? "border-b" : ""} `}>
                <div className="flex items-center gap-1">
                  <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
                </div>
              </td>
              <td className={`whitespace-nowrap px-2 py-2 text-sm  ${isLast(index) ? "border-b rounded-br-lg" : ""} `}>
                <Status status={transaction.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Amount = ({ amount, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`pr-2 py-1 rounded-sm flex justify-center items-center text-xs font-bold ${color}`}>
      {amount}
      {currency}
    </div>
  );
};

const Status = ({ status }) => {
  const { t } = useTranslation();
  if (status === "RECONCILED")
    return (
      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-600">
        <div className="h-2 w-2 rounded-full bg-green-400" />
        {t(`transactions.RECONCILED`)}
      </span>
    );
  if (status === "TO_RECONCILE")
    return (
      <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-600">
        <div className="h-2 w-2 rounded-full bg-red-400" />
        {t(`transactions.TO_RECONCILE`)}
      </span>
    );
};

const TransactionIcon = ({ transaction }) => {
  if (transaction.accountDetails === "petty_cash") return <FaCashRegister size={20} />;
  if (transaction.accountDetails === "savings") return <FaMoneyBill size={20} />;
  if (transaction.bankLogo) return <img src={transaction.bankLogo} height={20} width={20} />;
};

export default AllTransactions;
