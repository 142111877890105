import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import api from "@services/api";
import categories from "@data/categories.js";
import { Mixpanel } from "@services/mixpanel";
import { classNames, getAccountName, currencyToStr } from "@utils";
import { Select } from "@components/utils/Select";
import { Combobox } from "@components/utils/Combobox";
import CreateClient from "@components/modals/createClient";
import CreateEmployee from "@components/modals/createEmployee";
import CreateSupplier from "@components/modals/createSupplier";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

import { FaCashRegister, FaMoneyBill } from "react-icons/fa6";

const MultipleActionBar = ({ supplierOptions, clientOptions, employeeOptions, accountOptions, selectedTransactions, setSelectedTransactions, setLoadingTransaction, onSave }) => {
  const { t } = useTranslation();

  const updateMultipleTransactions = async ({ params }) => {
    setLoadingTransaction(true);

    try {
      let updatedTransactions = selectedTransactions;

      for (const transaction of selectedTransactions) {
        const { ok, data } = await api.put(`/transaction/${transaction._id}`, {
          ...params,
        });
        if (!ok) throw new Error(`Error updating transaction ${transaction.name}`);

        //sync the transaction with the selected transactions
        if (updatedTransactions.some((t) => t._id === data._id)) {
          const updatedSelectedTransactions = updatedTransactions.map((transaction) => {
            if (transaction._id === data._id) return { ...transaction, ...params };
            return transaction;
          });
          updatedTransactions = updatedSelectedTransactions;
        }
      }

      toast.success(t("toast.sucess.updated_sucessfully"));
      setSelectedTransactions(updatedTransactions);
      onSave();
    } catch (error) {
      console.log("error multiple update", error);
      toast.error(error.code);
    } finally {
      setLoadingTransaction(false);
    }
  };

  const reconcileMultipleTransactions = async (e) => {
    try {
      e.preventDefault();
      if (!window.confirm("Are you sure you want to reconcile these transactions?")) return;

      for (const transaction of selectedTransactions) {
        const { ok } = await api.put(`/transaction/${transaction._id}`, { status: "RECONCILED" });
        if (!ok) throw new Error(`Error reconciling transaction ${transaction.name}`);
      }

      setSelectedTransactions([]);
      onSave();
      toast.success(t("toast.success.reconciled"));
    } catch (e) {
      console.log("error multiple reconcile", e);
      toast.error(e.code);
    }
  };

  const canReconcileMultiple = () => {
    return selectedTransactions.every((transaction) => {
      if (!transaction.category || !transaction.type) return false;
      if (transaction.type === "CLIENT" && !transaction.ClientId) return false;
      if (transaction.type === "SUPPLIER" && !transaction.SupplierId) return false;
      if (transaction.type === "EMPLOYEE" && !transaction.EmployeeId) return false;
      if (transaction.type === "TRANSFER" && !transaction.TransferAccountId) return false;
      return true;
    });
  };

  return (
    <div className="bg-[#f5f6fc]">
      <div className="flex justify-between items-center p-2 gap-4 bg-white rounded-xl border border-gray-300">
        <div className={classNames("p-1 text-center text-sm font-medium transition-all duration-200 bg-white rounded-xl border-2 border-white")}>
          <div className="flex items-center justify-center gap-2">
            <span className="ml-2 text-sm bg-blue-200 px-2 py-0.5 rounded-xl text-blue-500 border border-blue-500 font-medium">{selectedTransactions.length}</span>
            {t("transactions_selected")}
          </div>
        </div>
        {!selectedTransactions.every((t) => t.category === "2100") && (
          <>
            <div className={`text-sm w-40`}>
              <Select
                options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
                value={selectedTransactions.every((transaction) => transaction.type === selectedTransactions[0].type) ? selectedTransactions[0].type : null}
                onChange={async (type) => {
                  updateMultipleTransactions({ params: { type: type } });
                }}
                getLabel={(e) => t(e.toLowerCase())}
                placeholder={t("type")}
                nullable={false}
              />
            </div>
            <div className={`text-sm w-48`}>
              {selectedTransactions.every((t) => t.type === selectedTransactions[0].type) &&
                (selectedTransactions[0].type === "SUPPLIER" ? (
                  <MultipleSupplierComboBox onUpdateTransaction={updateMultipleTransactions} supplierOptions={supplierOptions} selectedTransactions={selectedTransactions} />
                ) : selectedTransactions[0].type === "CLIENT" ? (
                  <MultipleClientComboBox onUpdateTransaction={updateMultipleTransactions} clientOptions={clientOptions} selectedTransactions={selectedTransactions} />
                ) : selectedTransactions[0].type === "EMPLOYEE" ? (
                  <MultipleEmployeeComboBox onUpdateTransaction={updateMultipleTransactions} employeeOptions={employeeOptions} selectedTransactions={selectedTransactions} />
                ) : selectedTransactions[0].type === "TRANSFER" ? (
                  <MultipleTransferSelect onUpdateTransaction={updateMultipleTransactions} accountOptions={accountOptions} selectedTransactions={selectedTransactions} />
                ) : null)}
            </div>
          </>
        )}
        <div className={`text-sm`}>
          <MultipleCategoryComboBox selectedTransactions={selectedTransactions} updateMultipleTransactions={updateMultipleTransactions} />
        </div>
        <div className={`text-sm`}>
          <button
            className="px-2 font-semibold text-xs   bg-black text-white w-max h-8 rounded-lg disabled:opacity-50  "
            onClick={(e) => {
              reconcileMultipleTransactions(e);
              Mixpanel.track(MIXPANEL_EVENTS.btn_reconcile_transactions);
            }}
            disabled={!canReconcileMultiple()}>
            {t("reconcile_all")}
          </button>
        </div>
      </div>
    </div>
  );
};

const MultipleClientComboBox = ({ onUpdateTransaction, clientOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState();
  const [showCreateClient, setShowCreateClient] = useState(false);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.ClientId === selectedTransactions[0].ClientId)) return setSelectedClientId(null);
    const clientId = clientOptions.find((client) => client._id === selectedTransactions[0].ClientId)?._id;
    setSelectedClientId(clientId);
  }, [selectedTransactions, clientOptions]);

  const handleChange = async (e) => {
    await onUpdateTransaction({ params: { ClientId: e?._id || null } });
    setSelectedClientId(e?._id || null);
  };

  const clientOption = (item) => {
    if (!item) return null;
    const clientLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    return clientLabel;
  };

  return (
    <>
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <div className="relative">
        <Combobox
          options={clientOptions}
          value={clientOptions.find((client) => client._id === selectedClientId)}
          onChange={async (e) => handleChange(e)}
          placeholder={t("client")}
          getLabel={(client) => clientOption(client)}
          nullable={true}
          listHeader={
            <button
              className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
              onClick={() => {
                setShowCreateClient(true);
              }}>
              {t("clients.create_a_client")} <span>+</span>
            </button>
          }
          displayValue={(client) => {
            return clientOption(client);
          }}
        />
      </div>
    </>
  );
};

const MultipleSupplierComboBox = ({ onUpdateTransaction, supplierOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedSupplierId, setSelectedSupplierId] = useState();
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.SupplierId === selectedTransactions[0].SupplierId)) return setSelectedSupplierId(null);
    const supplierId = supplierOptions.find((supplier) => supplier._id === selectedTransactions[0].SupplierId)?._id;
    setSelectedSupplierId(supplierId);
  }, [selectedTransactions, supplierOptions]);

  const handleChange = async (e) => {
    await onUpdateTransaction({ params: { SupplierId: e?._id || null } });
    setSelectedSupplierId(e?._id || null);
  };

  const supplierOption = (item) => {
    if (!item) return null;
    const supplierLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    return supplierLabel;
  };

  return (
    <>
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <div className="relative">
        <Combobox
          options={supplierOptions}
          value={supplierOptions.find((supplier) => supplier._id === selectedSupplierId)}
          onChange={async (e) => handleChange(e)}
          placeholder={t("supplier")}
          getLabel={(supplier) => supplierOption(supplier)}
          nullable={true}
          listHeader={
            <button
              className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
              onClick={() => {
                setShowCreateSupplier(true);
              }}>
              {t("supplier.create_supplier")} <span>+</span>
            </button>
          }
          displayValue={(supplier) => supplierOption(supplier)}
        />
      </div>
    </>
  );
};

const MultipleEmployeeComboBox = ({ onUpdateTransaction, employeeOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.EmployeeId === selectedTransactions[0].EmployeeId)) return setSelectedEmployeeId(null);
    const employeeId = employeeOptions.find((employee) => employee._id === selectedTransactions[0].EmployeeId)?._id;
    setSelectedEmployeeId(employeeId);
  }, [selectedTransactions, employeeOptions]);

  const handleChange = async (e) => {
    await onUpdateTransaction({ params: { EmployeeId: e?._id || null } });
    setSelectedEmployeeId(e?._id || null);
  };

  const employeeOption = (item) => {
    if (!item) return null;
    const employeeLabel = item.firstname + " " + item.lastname;
    return employeeLabel;
  };

  return (
    <>
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <div className="relative">
        <Combobox
          options={employeeOptions}
          value={employeeOptions.find((employee) => employee._id === selectedEmployeeId)}
          onChange={async (e) => handleChange(e)}
          placeholder={t("employee")}
          getLabel={(employee) => employeeOption(employee)}
          listHeader={
            <button
              className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
              onClick={() => {
                setShowCreateEmployee(true);
              }}>
              {t("employees.create")} <span>+</span>
            </button>
          }
          displayValue={(employee) => employeeOption(employee)}
        />
      </div>
    </>
  );
};

const MultipleTransferSelect = ({ onUpdateTransaction, accountOptions, selectedTransactions }) => {
  const { t } = useTranslation();
  const [selectedAccountId, setSelectedAccountId] = useState(null);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.TransferAccountId === selectedTransactions[0].TransferAccountId)) return setSelectedAccountId(null);
    const accountId = accountOptions.find((account) => account._id === selectedTransactions[0].TransferAccountId)?._id;
    setSelectedAccountId(accountId);
  }, [selectedTransactions, accountOptions]);

  return (
    <Select
      options={accountOptions}
      value={accountOptions.find((account) => account._id == selectedAccountId) || null}
      onChange={(e) => {
        onUpdateTransaction({ params: { TransferAccountId: e?._id || null } });
      }}
      placeholder={t("account")}
      getLabel={(account) => <AccountOption account={account} />}
      width="w-72"
      position="left-1/2 -translate-x-1/2 top-full"
      nullable={true}
    />
  );
};

const AccountOption = ({ account }) => {
  if (!account) return;

  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash") return <FaCashRegister className="h-5 w-5" />;
  if (account.details === "savings") return <FaMoneyBill className="h-5 w-5" />;
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-5 w-5" />;
};

const MultipleCategoryComboBox = ({ selectedTransactions, updateMultipleTransactions }) => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState(categories);

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  useEffect(() => {
    if (!selectedTransactions?.every((transaction) => transaction.category === selectedTransactions[0].category)) return setSelectedCategory(null);
    setSelectedCategory(selectedTransactions[0].category);
  }, [selectedTransactions]);

  return (
    <Combobox
      value={selectedCategory}
      options={filteredCategories}
      onChange={async (category) => {
        updateMultipleTransactions({ params: { category: category?.id || null }, needTransactionsUpdate: true });
      }}
      getLabel={(e) => {
        return e ? e.id + " - " + t(`transactions.${e.id}`) : null;
      }}
      displayValue={(e) => {
        if (!e) return null;
        return t(`transactions.${e}`);
      }}
      placeholder={t("category")}
    />
  );
};

export default MultipleActionBar;
