import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";

import EditSupplier from "./editSupplier";
import CreateSupplier from "@components/modals/createSupplier.jsx";

import ActionsMenu from "@components/utils/ActionsMenu";
import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";
import TruncatedText from "@components/utils/TruncatedText";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [filter, setFilter] = useState({ search: "", page: 1, per_page: 10 });
  const [total, setTotal] = useState(0);
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!organization) return;
    get();
  }, [filter, organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_suppliers);
  }, []);

  async function get() {
    const { data } = await api.post("/supplier/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });
    setSuppliers(data.suppliers);
    setTotal(data.total);
  }

  async function handleDelete(id) {
    const confirm = window.confirm(t("confirm_message"));
    if (!confirm) return;
    await api.remove(`/supplier/${id}`);
    toast.success(t("toast.sucess.removed"));
    get();
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("suppliers")}</h1>
              <p className="mt-2 text-slate-600">{t("suppliers.intro")}</p>
            </div>
            <div className="flex gap-2">
              <button className="btn-primary focus:bg-blue float-right mb-2"> {t("import_from_csv")} </button>
              <button className="btn-primary focus:bg-blue float-right mb-2" onClick={() => setShowCreateSupplier(true)}>
                {t("supplier.create")}
              </button>
            </div>
          </div>
          <EditSupplier supplier={selectedSupplier} setSupplier={() => setSelectedSupplier(null)} onSave={get} />
          <div className="flex flex-col space-y-5 mt-8">
            <SearchBar search={filter.search} setFilter={setFilter} />
            <Table
              suppliers={suppliers}
              onEdit={(supplier) => {
                setSelectedSupplier(supplier);
              }}
              onDelete={handleDelete}
            />
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => setShowCreateSupplier()}
        onCreated={(el) => {
          get();
          setSelectedSupplier(el);
        }}
      />
    </div>
  );
};

const Table = ({ suppliers, onEdit, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900 border-r uppercase">
              {t("name")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("supplier_type")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("email")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("phone_number")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {suppliers.map((supplier) => (
            <tr key={supplier._id}>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r">
                <span
                  className="cursor-pointer font-bold underline"
                  onClick={() => {
                    onEdit(supplier);
                    Mixpanel.track(MIXPANEL_EVENTS.page_view_suppliers_edit);
                  }}>
                  <TruncatedText str={supplier.entityType === "COMPANY" ? supplier?.companyName : `${supplier?.firstname} ${supplier?.lastname}`} />
                </span>
              </td>

              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {supplier.entityType === "INDIVIDUAL" ? t("individual") : supplier.entityType === "COMPANY" ? t("company") : null}
              </td>

              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {supplier.email ? (
                  <a href={`mailto:${supplier?.email}`} className="text-blue-500">
                    <TruncatedText str={supplier?.email} />
                  </a>
                ) : (
                  <TruncatedText str={supplier?.email} />
                )}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {supplier.phone ? (
                  <a href={`tel:${supplier?.phone}`} className="text-blue-500">
                    <TruncatedText str={supplier?.phone} />
                  </a>
                ) : (
                  <TruncatedText str={supplier?.phone} />
                )}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <ActionsMenu
                  actions={[
                    {
                      label: t("edit"),
                      callback: () => {
                        onEdit(supplier);
                        Mixpanel.track(MIXPANEL_EVENTS.page_view_suppliers_edit);
                      },
                    },
                    { label: t("delete"), callback: () => onDelete(supplier._id) },
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
