import { useState, useEffect } from "react";
import api from "@services/api";
import toast from "react-hot-toast";
import Modal from "@components/utils/Modal";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel.js";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents.js";
import { RxCross1 } from "react-icons/rx";

const CreateProduct = ({ onCreated, open, onClose }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const { organization } = useSelector((state) => state.Auth);
  const [isLoading, setIsLoading] = useState(false);

  async function onSave(event) {
    try {
      setIsLoading(true);
      event.preventDefault();
      const { data } = await api.post("/product", { ...values, OrganizationId: organization._id });
      toast.success(t("toast.sucess.created"));
      onClose();
      onCreated(data);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      Mixpanel.track(MIXPANEL_EVENTS.btn_open_product_create_modal);
    }
    setValues();
  }, [open]);

  return (
    <div>
      <Modal isOpen={open} onClose={() => onClose()} innerClassName="flex max-w-[40rem] w-[90vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-8 pt-8 pb-3 border-b flex justify-between">
            <h2 className="text-2xl font-semibold text-gray-800">{t("products.creating_a_product")}</h2>
            <button type="submit" onClick={onClose}>
              <RxCross1 />
            </button>
          </div>
          <div className="p-6 overflow-auto">
            <form className="flex flex-col gap-4" onSubmit={onSave}>
              <div className="grid grid-cols-2 grid-rows-1 gap-6">
                <div className="flex flex-col row-start-1">
                  <div className="text-xs text-gray-500 pb-1">{t("name")}</div>
                  <input
                    required
                    type="text"
                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                    className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                    placeholder={t("name")}
                  />
                </div>
                <div className="flex flex-col row-start-1">
                  <div className="text-xs text-gray-500 pb-1">{t("reference")}</div>
                  <input
                    required
                    type="text"
                    onChange={(e) => setValues({ ...values, reference: e.target.value })}
                    className="py-1 px-3 bg-white border border-gray-300 rounded-md hover:border-sky-700 w-full"
                    placeholder={t("reference")}
                  />
                </div>
              </div>
              <div className="flex justify-end mt-4 gap-6">
                <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_create_product)}>
                  {t("create")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateProduct;
