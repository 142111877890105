import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import api from "@services/api";
import Modal from "@components/utils/Modal";

import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";

import PaymentLinkLogo from "@assets/payment-link-logo.png";
import Waiting from "@assets/payment-link-waiting.png";
import ActionRequired from "@assets/action-required-logo.png";

import { ENVIRONMENT } from "../../../config";
import { RxCross1 } from "react-icons/rx";
import { FaRegCheckCircle } from "react-icons/fa";

const PaymentModal = ({ isOpen, onClose, onUpdate }) => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const [gocardlessStatus, setGocardlessStatus] = useState("not_connected");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (organization?.gocardlessAccessToken) getGocardlessAccountStatus();
    setLoading(false);
  }, []);

  const getGocardlessAccountStatus = async () => {
    try {
      const { data } = await api.get(`/gocardless/status/${organization._id}`);
      setGocardlessStatus(data.creditor.verification_status);
    } catch (error) {
      console.error("Error fetching Gocardless account status:", error);
    }
  };

  const redirectGocardless = async () => {
    try {
      Mixpanel.track(MIXPANEL_EVENTS.btn_connect_gocardless_settings);
      setLoading(true);
      const { data } = await api.post("/gocardless/connect", {
        path: `/settings/my-organizations`,
      });
      if (!data.link) return toast.error(t("settings.gocardless.error_no_link"));
      window.location.href = data.link;
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };

  if (loading) {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative">
          <Loader />
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "not_connected") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="flex flex-col py-8 px-8">
          <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
            <RxCross1 className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-bold mb-4">{t("settings.gocardless_link")}</h1>
          <p className="text-gray-500 font-light text-sm mb-6">{t("settings.gocardless_decription")}</p>
          <div className="flex justify-center items-center gap-20">
            <div className="flex-shrink-0">
              <img src={PaymentLinkLogo} alt="Payment link illustration" className="w-72" />
            </div>
            <div className="flex flex-col">
              <h2 className="text-base text-gray-600 font-thin mb-4">{t("settings.gocardless.benefits_title")}</h2>
              <div className="space-y-3 text-gray-600 mb-8">
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("settings.gocardless.benefit_immediate_payment")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("settings.gocardless.benefit_no_waiting")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("settings.gocardless.benefit_faster_process")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("settings.gocardless.benefit_cash_flow")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("settings.gocardless.benefit_security")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("settings.gocardless.benefit_powered_by")}</span>
                </div>
              </div>
              <p className="text-blue-500 mb-8">{t("settings.gocardless.activation_message")}</p>
            </div>
          </div>
          <div className="flex justify-end w-full">
            <button className="btn-primary px-20" onClick={redirectGocardless}>
              {t("settings.gocardless.btn_lets_go")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "action_required") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="flex flex-col py-8 px-8">
          <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
            <RxCross1 className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-bold mb-4">{t("settings.gocardless.action_required")}</h1>
          <p className="text-gray-500 font-light text-sm mb-6">{t("settings.gocardless.action_required_description")}</p>
          <div className="flex justify-center items-center gap-8 px-4">
            <img src={ActionRequired} alt="Illustration action requise" className="w-48 ml-12" />
          </div>
          <div className="flex justify-end w-full mt-8">
            <button
              className="btn-primary px-10 py-3"
              onClick={() => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_action_required_gocardless_settings);
                const url = ENVIRONMENT === "development" ? "https://verify-sandbox.gocardless.com" : "https://verify.gocardless.com";
                window.location.href = url;
              }}>
              {t("settings.gocardless.btn_goto")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "in_review") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="flex flex-col justify-center py-8 px-8 min-h-[50vh]">
          <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
            <RxCross1 className="w-6 h-6" />
          </button>

          <h1 className="text-2xl font-bold mb-4">{t("settings.gocardless.in_review")}</h1>
          <p className="text-gray-500 font-light text-sm mb-6">{t("settings.gocardless.in_review_message")}</p>
          <div className="flex flex-col items-center gap-4">
            <img src={Waiting} alt="Illustration action requise" className="w-36" />
            <p className="text-gray-500 text-center font-light text-sm mt-4 w-1/2">{t("settings.gocardless.in_review_additional_info")}</p>
            <p className="text-blue-500 mb-8">{t("settings.gocardless.delay_message")}</p>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
      <div className="flex flex-col justify-center py-8 px-8 min-h-[50vh]">
        <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
          <RxCross1 className="w-6 h-6" />
        </button>
        <div className="flex flex-col items-center gap-4">
          <FaRegCheckCircle className="w-12 h-12 text-green-500 mb-4" />
          <h2 className="text-2xl font-bold text-center">{t("settings.gocardless.account_configured")}</h2>
          <p className="text-gray-500 text-center max-w-lg">{t("settings.gocardless.can_create_payment_links")}</p>
          <img src={PaymentLinkLogo} alt="Payment Link" className="w-48 my-8" />
          <button onClick={onClose} className="btn-primary px-10 py-3">
            {t("settings.gocardless.quit")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const CheckIcon = () => {
  return (
    <div className="bg-blue-50 rounded-full p-1">
      <svg className="w-4 h-4 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
    </div>
  );
};

export default PaymentModal;
