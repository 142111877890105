import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAccountName, currencyToStr } from "@utils";
import { useSelector } from "react-redux";

import { Combobox } from "@components/utils/Combobox";
import { Select } from "@components/utils/Select";
import CreateClient from "@components/modals/createClient";
import CreateEmployee from "@components/modals/createEmployee";
import CreateSupplier from "@components/modals/createSupplier";

import { FaCashRegister, FaMoneyBill } from "react-icons/fa6";

const ThirdPartySelection = ({ transaction, updateTransaction, supplierOptions, clientOptions, employeeOptions, accountOptions }) => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const showAiSuggestions = ["a4f2f18b-baf3-49f8-a3a7-e922e1cd98c0", "8f0ca818-7142-4f47-be67-3ba00f44277e"].includes(organization?._id);

  const getClientLabel = (client) => {
    return client?.entityType === "INDIVIDUAL" ? client?.firstname + " " + client?.lastname : client?.companyName || "";
  };

  const getSupplierLabel = (supplier) => {
    return supplier?.entityType === "INDIVIDUAL" ? supplier?.firstname + " " + supplier?.lastname : supplier?.companyName || "";
  };

  if (transaction.category === "2100") return null;

  if (transaction.type === "SUPPLIER")
    return (
      <div className="relative">
        <SupplierComboBox transaction={transaction} onUpdateTransaction={updateTransaction} supplierOptions={supplierOptions} />
        {showAiSuggestions && transaction.AiSupplierId && (
          <div className="absolute -bottom-0.5 text-[10px]">
            AI : {getSupplierLabel(supplierOptions.filter((s) => s._id === transaction.AiSupplierId)[0])} - {Math.round(transaction.aiSupplierIdConfidence * 100)}%
          </div>
        )}
      </div>
    );

  if (transaction.type === "CLIENT")
    return (
      <div className="relative">
        <ClientComboBox transaction={transaction} onUpdateTransaction={updateTransaction} clientOptions={clientOptions} />
        {showAiSuggestions && transaction.AiClientId && (
          <div className="absolute -bottom-0.5 text-[10px]">
            AI : {getClientLabel(clientOptions.filter((c) => c._id === transaction.AiClientId)[0])} - {Math.round(transaction.aiClientIdConfidence * 100)}%
          </div>
        )}
      </div>
    );

  if (transaction.type === "EMPLOYEE") return <EmployeeComboBox transaction={transaction} onUpdateTransaction={updateTransaction} employeeOptions={employeeOptions} />;

  if (transaction.type === "TRANSFER")
    return (
      <Select
        options={accountOptions}
        value={accountOptions.find((account) => account._id == transaction.TransferAccountId) || null}
        onChange={(e) => {
          updateTransaction({ params: { ...transaction, TransferAccountId: e?._id || null } });
        }}
        placeholder={t("account")}
        getLabel={(account) => <AccountOption account={account} />}
        width="w-full"
        nullable={true}
      />
    );

  return null;
};

const AccountOption = ({ account }) => {
  if (!account) return;

  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash") return <FaCashRegister className="h-5 w-5" />;
  if (account.details === "savings") return <FaMoneyBill className="h-5 w-5" />;
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-5 w-5" />;
};

const ClientComboBox = ({ transaction, onUpdateTransaction, clientOptions }) => {
  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState();
  const [showCreateClient, setShowCreateClient] = useState(false);

  useEffect(() => {
    setSelectedClientId(transaction?.ClientId || transaction?.ClientIdSuggestion || null);
  }, [transaction]);

  const handleChange = (e) => {
    onUpdateTransaction({
      params: {
        ...transaction,
        ClientId: e?._id || null,
        category: transaction.category || e?.defaultCategory,
      },
      needTransactionsUpdate: true,
    });
    setSelectedClientId(e?._id || null);
  };

  const clientOption = (item) => {
    if (!item) return null;
    const clientLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.ClientId && transaction?.ClientIdSuggestion === item._id) return `${clientLabel} (${t("suggested")})`;
    return clientLabel;
  };

  return (
    <>
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <Combobox
        options={clientOptions}
        value={clientOptions.find((client) => client._id === selectedClientId)}
        onChange={async (e) => handleChange(e)}
        placeholder={t("client")}
        getLabel={(client) => clientOption(client)}
        nullable={true}
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
            onClick={() => {
              setShowCreateClient(true);
            }}>
            {t("clients.create_a_client")} <span>+</span>
          </button>
        }
        displayValue={(client) => {
          return clientOption(client);
        }}
      />
    </>
  );
};

const EmployeeComboBox = ({ transaction, onUpdateTransaction, employeeOptions }) => {
  const { t } = useTranslation();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);

  useEffect(() => {
    setSelectedEmployeeId(transaction?.EmployeeId || transaction?.EmployeeIdSuggestion || null);
  }, [transaction]);

  const handleChange = (e) => {
    onUpdateTransaction({
      params: {
        ...transaction,
        EmployeeId: e?._id || null,
        category: transaction.category || e?.defaultCategory,
      },
      needTransactionsUpdate: true,
    });
    setSelectedEmployeeId(e?._id || null);
  };

  const employeeOption = (item) => {
    if (!item) return null;
    const employeeLabel = item.firstname + " " + item.lastname;
    if (!transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === item._id) return `${employeeLabel} (${t("suggested")})`;
    return employeeLabel;
  };

  return (
    <>
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <Combobox
        options={employeeOptions}
        value={employeeOptions.find((employee) => employee._id === selectedEmployeeId)}
        onChange={async (e) => handleChange(e)}
        placeholder={t("employee")}
        getLabel={(employee) => employeeOption(employee)}
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
            onClick={() => {
              setShowCreateEmployee(true);
            }}>
            {t("employees.create")} <span>+</span>
          </button>
        }
        displayValue={(employee) => {
          return employeeOption(employee);
        }}
      />
    </>
  );
};

const SupplierComboBox = ({ transaction, onUpdateTransaction, supplierOptions }) => {
  const { t } = useTranslation();
  const [selectedSupplierId, setSelectedSupplierId] = useState();
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);

  useEffect(() => {
    setSelectedSupplierId(transaction?.SupplierId || transaction?.SupplierIdSuggestion || null);
  }, [transaction]);

  const handleChange = (e) => {
    onUpdateTransaction({
      params: {
        ...transaction,
        SupplierId: e?._id || null,
        category: transaction.category || e?.defaultCategory,
      },
      needTransactionsUpdate: true,
    });
    setSelectedSupplierId(e?._id || null);
  };

  const supplierOption = (item) => {
    if (!item) return null;
    const supplierLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.SupplierId && transaction?.SupplierIdSuggestion === item._id) return `${supplierLabel} (${t("suggested")})`;
    return supplierLabel;
  };

  return (
    <>
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          handleChange(data);
        }}
      />
      <Combobox
        options={supplierOptions}
        value={supplierOptions.find((supplier) => supplier._id === selectedSupplierId)}
        onChange={async (e) => handleChange(e)}
        placeholder={t("supplier")}
        getLabel={(supplier) => supplierOption(supplier)}
        nullable={true}
        listHeader={
          <button
            className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm"
            onClick={() => {
              setShowCreateSupplier(true);
            }}>
            {t("supplier.create_supplier")} <span>+</span>
          </button>
        }
        displayValue={(supplier) => supplierOption(supplier)}
      />
    </>
  );
};

export default ThirdPartySelection;
