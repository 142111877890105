import { useRef, useEffect } from "react";
import { HiX, HiOutlineLightBulb, HiOutlineClipboardList } from "react-icons/hi";

const HelpCenter = ({ isOpen, setIsOpen }) => {
  const helpCenterRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Utilisons la classe spécifique help-center-toggle
      const isToggleButton = event.target.closest(".help-center-toggle");

      if (!isToggleButton && helpCenterRef.current && !helpCenterRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  const handleCrispChat = () => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
      setIsOpen(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div ref={helpCenterRef} className="absolute inset-0 bg-white sm:inset-auto sm:top-16 sm:right-4 sm:w-[400px] sm:rounded-lg shadow-lg overflow-hidden">
      {/* Header */}
      <div className="flex flex-col gap-2 p-6 border-b bg-blue-100">
        <div className="flex justify-between items-center gap-2">
          <h2 className="text-xl font-semibold">Besoin d'aide ?</h2>
          <button onClick={() => setIsOpen(false)} className="p-1 hover:bg-opacity-30 hover:bg-white rounded-full transition-all duration-200">
            <HiX className="w-6 h-6" />
          </button>
        </div>
        <p className="text-sm text-gray-500">Retrouvez tout ce dont vous avez besoin ici.</p>
      </div>

      {/* Help Options */}
      <div className="py-4">
        {/* Chat Option */}
        <div onClick={handleCrispChat} className="flex items-center px-4 space-x-4 p-3 hover:bg-gray-50 rounded-lg cursor-pointer">
          <div className="w-8 flex items-center justify-center">
            <HiOutlineClipboardList className="w-8 h-8 text-blue-500 mt-1" strokeWidth={1.5} />
          </div>
          <div className="w-full">
            <h3 className="font-medium text-base">Discutez avec nous</h3>
            <p className="text-sm text-gray-500">Notre équipe vous répondra dès que possible sous un délai maximum de 24h ouvrées.</p>
          </div>
        </div>

        {/* SFI Question Option */}
        <a
          href="https://societe-france-irlande.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-4 p-3 hover:bg-gray-50 rounded-lg cursor-pointer">
          <div className="w-8 flex items-center justify-center">
            <HiOutlineLightBulb className="w-8 h-8 text-blue-500 mt-1" strokeWidth={1.5} />
          </div>

          <div className="w-full">
            <h3 className="font-medium text-base">Une question sur SFI et/ou Neotoria ?</h3>
            <p className="text-sm text-gray-500">Vous avez fait appel à Société France Irlande, et vous avez des questions ? L'équipe SFI est là pour vous aider.</p>
          </div>
        </a>

        {/* Help Center Option */}
        {/* <div className="flex items-start space-x-4 p-3 hover:bg-gray-50 rounded-lg cursor-pointer">
            <HiOutlineChat className="w-16 h-16 text-blue-500 mt-1" strokeWidth={1.5} />
            <div>
              <h3 className="font-medium">Centre d'aide</h3>
              <p className="text-sm text-gray-500">Trouvez des réponses, des conseils et des solutions à vos questions dans notre FAQ et nos articles.</p>
            </div>
          </div> */}
      </div>
    </div>
  );
};

export default HelpCenter;
