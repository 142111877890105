import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import html2pdf from "html2pdf.js";
import { Link, useNavigate } from "react-router-dom";

import EditClient from "../clients/editClient";
import api from "@services/api";
import { currencyToStr, getClientName } from "@utils";
import { useSelector } from "react-redux";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { LuFile, LuSettings, LuTrash2 } from "react-icons/lu";

import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { Combobox } from "@components/utils/Combobox";
import FilesInput from "../../components/utils/FilesInput";
import { useTranslation } from "react-i18next";
import { apiURL } from "@config";
import { Mixpanel } from "@services/mixpanel";
import CreateProduct from "@components/modals/createProduct";
import CreateClient from "@components/modals/createClient";
import UpdateOrganization from "@components/modals/updateOrganization";
import { getTranslation } from "../../lang/invoice-translation";
import InvoiceParameter from "./components/invoiceParameter";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import FileInput from "../../components/utils/FileInput";
import { hexToRgb } from "../../utils";

const Editor = ({ invoice, onFinish }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({ ...invoice });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const organization = useSelector((state) => state.Auth.organization);
  const user = useSelector((state) => state.Auth.user);
  const invoiceRef = useRef();
  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_invoice_editor);
  }, []);

  const updateInvoice = async () => {
    try {
      const messageBody = values.Organization.bodyEmailTemplate
        ? values.Organization.bodyEmailTemplate
        : t("invoices.editor.message_body_default", {
            organization: values.Organization?.name,
            reference: values.reference,
            amount: values.totalTaxIncluded,
            currency: currencyToStr(values.currency ?? "EUR"),
            dueAt: new Date(values.dueAt).toLocaleDateString(),
            sender: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
          });

      const invoiceProducts = values.InvoiceProducts.filter((p) => p.ProductId);
      const { ok } = await api.put(`/invoice/${values._id}`, {
        ...values,
        InvoiceProducts: invoiceProducts,
        messageBody: messageBody,
        language: values.language,
      });
      if (!ok) throw new Error("Error updating invoice");
      toast.success(t("toast.sucess.invoice_updated"));
    } catch (error) {
      console.error("Error updating invoice", error);
      toast.error(t("toast.error.invoice_updating"));
    }
  };

  async function get() {
    try {
      const { data: invoiceData } = await api.get(`/invoice/${values?._id}`);
      setValues(invoiceData);
    } catch (error) {
      console.error(t("toast.error"), error);
      toast.error(error.code);
    }
  }

  // at each change of invoice, we recompute taxes & totals for each product and the invoice
  const handleInvoiceChange = (newInvoice) => {
    // compute taxValue & totalTaxIncluded for each product with discount
    const updatedProducts = newInvoice.InvoiceProducts.map((product) => {
      const taxRate = newInvoice.TaxId ? organization.Taxes.find((tax) => tax._id === newInvoice.TaxId)?.value : organization.Taxes.find((tax) => tax._id === product.TaxId)?.value;
      const baseTaxValue = parseFloat((product.price * product.quantity * taxRate) / 100).toFixed(2);
      const taxValue = parseFloat(baseTaxValue - (newInvoice.discount / 100) * baseTaxValue).toFixed(2);
      const subTotal = parseFloat(product.price * product.quantity).toFixed(2);
      return {
        ...product,
        TaxId: newInvoice.TaxId || product.TaxId,
        taxValue,
        subTotal,
        totalTaxIncluded: parseFloat(subTotal) + parseFloat(taxValue),
      };
    });

    const subTotal = parseFloat(updatedProducts.reduce((acc, product) => acc + parseFloat(product.subTotal), 0)).toFixed(2);
    const totalTaxExcluded = parseFloat(subTotal - (newInvoice.discount / 100) * subTotal);
    const taxValue = parseFloat(updatedProducts.reduce((acc, product) => acc + parseFloat(product.taxValue), 0)).toFixed(2);

    const updatedInvoice = {
      ...newInvoice,
      InvoiceProducts: updatedProducts,
      subTotal,
      taxValue,
      totalTaxExcluded,
      totalTaxIncluded: parseFloat(parseFloat(totalTaxExcluded) + parseFloat(taxValue)).toFixed(2),
    };

    setValues(updatedInvoice);
  };

  if (!values) return <Loader />;

  return (
    <div className="my-4 mx-8 rounded-lg bg-white relative">
      {loadingPreview && (
        <div className="absolute top-0 left-0 w-full h-full backdrop-blur-sm z-50 flex pt-12 justify-center">
          <div>
            <Loader />
          </div>
        </div>
      )}
      <PreviewModal className="z-20" isOpen={previewOpen} invoice={values} onClose={() => setPreviewOpen(false)} />
      <div className="flex p-4 gap-8">
        <div className="flex justify-center w-full p-2 rounded-lg border-2 border-gray-400/10">
          <Builder
            invoiceRef={invoiceRef}
            invoice={values}
            taxOptions={organization.Taxes}
            setInvoice={handleInvoiceChange}
            updateAndGetInvoice={async () => {
              await updateInvoice();
              get();
            }}
          />
        </div>
        <div className="w-80">
          <SideMenu
            invoice={values}
            setInvoice={handleInvoiceChange}
            handleSave={updateInvoice}
            get={get}
            setLoadingPreview={setLoadingPreview}
            setPreviewOpen={setPreviewOpen}
            invoiceRef={invoiceRef}
            onFinish={onFinish}
          />
        </div>
      </div>
    </div>
  );
};

const SideMenu = ({ invoice, setInvoice, handleSave, setLoadingPreview, setPreviewOpen, get, onFinish }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function generatePDF() {
    try {
      setLoadingPreview(true);
      await handleSave();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const res = await api.post(`/invoice/${invoice._id}/pdf`);
      if (!res.ok) throw res.code;
      return res.data;
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    } finally {
      setLoadingPreview(false);
    }
  }

  const handleFinish = async () => {
    try {
      if (invoice.InvoiceProducts.some((product) => !product.TaxId)) return toast.error(t("toast.error.invoice.tax_error"));
      await handleSave();
      if (!invoice.ClientId) return toast.error(t("toast.error.invoice.client_missing"));
      if (!invoice.InvoiceProducts.length) return toast.error(t("toast.error.invoice.product_missing"));
      if (invoice.InvoiceProducts.some((product) => !product.ProductId)) return toast.error(t("toast.error.invoice.line_error"));

      if (!window.confirm(t("invoices.editor.confirm_finish"))) return;
      await generatePDF();
      const generateInvoice = await api.put(`/invoice/finish/${invoice._id}`);
      if (!generateInvoice.ok) throw generateInvoice;
      Mixpanel.track(MIXPANEL_EVENTS.btn_finished_invoice);

      toast.success(t("toast.sucess.invoice_generated"));
      onFinish();
    } catch (error) {
      console.error(t("toast.error.invoice_generating"), error);
      toast.error(error.code);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center mb-2">
          <span className={`inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600`}>
            <div className={`h-2 w-2 rounded-full bg-gray-400`} />
            {t("invoices.status.DRAFT")}
          </span>
        </div>
        <button onClick={handleFinish} className="btn-primary w-full">
          {t("invoices.editor.finish")}
        </button>
        <button
          className="btn-secondary w-full"
          onClick={async () => {
            await handleSave();
            Mixpanel.track(MIXPANEL_EVENTS.btn_save_invoice);
            get();
          }}>
          <span className="">{t("invoices.editor.save_draft")}</span>
        </button>
        <button
          className="btn-secondary w-full"
          onClick={async () => {
            const data = await generatePDF();
            setInvoice({ ...invoice, ...data });
            setPreviewOpen(true);
          }}>
          <span className="">{t("invoices.editor.preview")}</span>
        </button>
      </div>

      <hr className="border-gray-300" />

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4 justify-between">
          <div className="font-semibold">{t("invoices.editor.options")}</div>
          <button
            onClick={() => {
              if (!window.confirm(t("invoices.editor.confirm_goto_settings"))) return;
              navigate("/settings");
            }}
            className="text-sm hover:text-gray-700 border border-gray-300 rounded-md p-2 group relative">
            <LuSettings size={16} />
            <span className="absolute -top-8 right-0 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
              {t("invoices.editor.goto_settings")}
            </span>
          </button>
        </div>
      </div>

      <InvoiceParameter invoice={invoice} setInvoice={setInvoice} handleSave={handleSave} />

      <div>
        <div className="flex justify-between items-center mt-2">
          <div className="text-sm mb-1">{t("invoices.editor.terms_conditions")}</div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={invoice.showTerms} onChange={() => setInvoice({ ...invoice, showTerms: !invoice.showTerms })} className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-400" />
          </label>
        </div>
        <div className="w-4/5 text-xs text-gray-500">{t("invoices.editor.options.terms_conditions_desc")}</div>
      </div>

      <hr className="border-gray-300" />

      <textarea
        type="text"
        value={invoice.memo ?? ""}
        onChange={(e) => setInvoice({ ...invoice, memo: e.target.value })}
        className="w-full min-h-[120px] p-3 border border-gray-200 rounded-lg 
                   text-gray-700 placeholder:text-gray-400
                   resize-none"
        placeholder={t("memo_note.to_enter.placeholder")}
        rows={5}
      />

      <div className="font-semibold">{t("invoices.editor.upload_memo_files")}</div>
      <FileInput
        onChange={async (file) => {
          setInvoice({ ...invoice, memoFiles: [file.target.value, ...(invoice.memoFiles || [])] });
          toast.success(t("toast.sucess.created"));
        }}
        name={invoice._id}
        folder={`/invoice/${invoice._id}/memoFiles`}
        width="w-full"
      />
      {invoice.memoFiles?.map((memoFile, index) => {
        return (
          <div key={memoFile._id} className="border rounded-lg p-4 relative">
            <div className="flex justify-between items-start mb-2">
              <a href={memoFile.file} target="_blank" rel="noopener noreferrer" className="text-black">
                <LuFile size={16} />
              </a>
              <button
                type="button"
                onClick={() => {
                  if (!window.confirm(t("invoices.editor.confirm_delete_memo_file"))) return;
                  setInvoice({ ...invoice, memoFiles: invoice.memoFiles.filter((_, i) => i !== index) });
                }}
                className="text-black hover:text-red-500">
                <LuTrash2 size={17} />
              </button>
            </div>
            <a href={memoFile} target="_blank" rel="noopener noreferrer" className="font-semibold text-sm hover:underline">
              {decodeURIComponent(memoFile.split("/").pop())}
            </a>
          </div>
        );
      })}
    </div>
  );
};

const Builder = ({ invoiceRef, invoice, setInvoice, updateAndGetInvoice, taxOptions }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center w-full gap-1">
      <div className="relative bg-white text-black">
        <div id="invoiceToGenerate" ref={invoiceRef} className="px-4 pt-4">
          <Header invoice={invoice} setInvoice={setInvoice} updateAndGetInvoice={updateAndGetInvoice} />
          <div className="pt-4 font-semibold text-xl">
            {getTranslation("Invoice", invoice.language)} {invoice.reference}
          </div>
          <InvoiceDates invoice={invoice} setInvoice={setInvoice} />
          <AmountTable invoice={invoice} setInvoice={setInvoice} taxOptions={taxOptions} />
          <SummaryTable invoice={invoice} setInvoice={setInvoice} taxOptions={taxOptions} />
          {invoice.showTerms && (
            <div className="flex flex-col mt-4">
              <h3 className="font-semibold text-base">{getTranslation("invoices_editor_terms_conditions", invoice.language)}</h3>
              <textarea
                className="w-full min-h-[70px] text-xs p-3 border border-gray-200 
                   text-gray-700 placeholder:text-gray-400
                   resize-none"
                value={invoice.termsBody}
                onChange={(e) => setInvoice({ ...invoice, termsBody: e.target.value })}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function getBase64file(url) {
  return new Promise((resolve, reject) => {
    let imagePath = "";

    if (url.startsWith("https://finotor.cellar-c2.services.clever-cloud.com")) {
      imagePath = url.replace("https://finotor.cellar-c2.services.clever-cloud.com", "");
      url = apiURL + "/proxy" + imagePath;
    }

    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Network response error ${res.status}`);
        }
        return res.blob();
      })
      .then((image) => {
        var reader = new FileReader();
        reader.onload = function () {
          var dataURL = this.result;
          resolve(dataURL);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(image);
      })
      .catch((error) => {
        console.error("Error getting logo", error);
        reject(error);
      });
  });
}

const Header = ({ invoice, setInvoice, updateAndGetInvoice }) => {
  const { t } = useTranslation();
  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [openOrganizationParams, setOpenOrganizationParam] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const organization = useSelector((state) => state.Auth.organization);

  async function getAllClients() {
    const { data } = await api.post("/client/search", { per_page: 1000, OrganizationId: organization._id });
    setClientOptions(data.clients);
  }

  useEffect(() => {
    if (!organization) return;
    getAllClients();
  }, [organization]);

  useEffect(() => {
    if (!invoice.Organization?.logo) return setOrganizationLogo(null);
    getBase64file(invoice.Organization?.logo).then((logo) => setOrganizationLogo(logo));
  }, [invoice.Organization?.logo]);

  const missingInformation =
    !invoice.Organization?.iban ||
    !invoice.Organization?.vat ||
    !invoice.Organization?.registrationNumber ||
    !invoice.Organization?.email ||
    !invoice.Organization?.billingAddressStreet ||
    !invoice.Organization?.billingAddressCity ||
    !invoice.Organization?.billingAddressZipCode ||
    !invoice.Organization?.billingAddressCountry;

  return (
    <div>
      <header className="grid justify-center grid-cols-2 gap-x-10">
        <div className="flex flex-col items-start justify-start h-full">
          <div className="flex flex-col justify-between w-5/6 h-20 mb-3">
            <p className="font-semibold">{t("client")}</p>
            <Combobox
              options={clientOptions}
              value={clientOptions.find((client) => client._id === invoice.ClientId)}
              onChange={(e) => {
                setInvoice({ ...invoice, ClientId: e?._id || null, Client: e || null, recipients: [...(e?.emails ?? [])] });
              }}
              listHeader={
                <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateClient(true)}>
                  {t("clients.create_a_client")} <span>+</span>
                </button>
              }
              placeholder={t("client")}
              getLabel={(client) => clientorSupplierOption(client)}
            />
          </div>

          <div className="flex gap-3 items-center mb-2">
            <h1 className="text-black text-2xl font-semibold">{getClientName(invoice.Client)}</h1>
          </div>
          {invoice.Client?.billingAddressStreet && (
            <p className="text-sm py-1">
              <span>{getTranslation("address", invoice.language)} : </span>
              <span className="text-gray-500">
                {[invoice.Client?.billingAddressStreet, invoice.Client?.billingAddressZipCode, invoice.Client?.billingAddressCity, invoice.Client?.billingAddressCountry]
                  .filter((address) => address)
                  .join(", ")}
              </span>
            </p>
          )}
          {invoice.Client?.emails && (
            <p className="text-sm py-1">
              <span>{t("email")} : </span>
              <span className="text-gray-500">{invoice.Client?.emails[0]}</span>
            </p>
          )}
          {invoice.Client?.registrationNumber && (
            <p className="text-sm py-1">
              <span>{getTranslation("registration_number", invoice.language)} : </span>
              <span className="text-gray-500">{invoice.Client?.registrationNumber}</span>
            </p>
          )}
          {invoice.Client?.vatNumber && (
            <p className="text-sm py-1">
              <span>{getTranslation("vat_number", invoice.language)} : </span>
              <span className="text-gray-500">{invoice.Client?.vatNumber}</span>
            </p>
          )}
        </div>
        <div className="col-start-2 h-full">
          <div className="flex flex-col gap-3 items-start mb-2">
            <div className="cursor-pointer" onClick={() => setOpenOrganizationParam(true)}>
              {organizationLogo ? (
                <img alt={invoice.Organization?.name} className="h-20 aspect-auto" src={organizationLogo} />
              ) : (
                <div className="h-20 w-20 flex items-center justify-center rounded-xl bg-blue-500 text-white font-bold cursor-pointer text-4xl">
                  {invoice.Organization?.name.charAt(0)}
                </div>
              )}
            </div>

            <h1 className="text-black text-2xl font-semibold flex-1">{invoice.Organization?.name}</h1>
          </div>
          {[
            invoice.Organization?.billingAddressStreet,
            invoice.Organization?.billingAddressZipCode,
            invoice.Organization?.billingAddressCity,
            invoice.Organization?.billingAddressCountry,
          ].filter((address) => address).length > 0 && (
            <p className="text-sm py-1 text-left">
              <span>{getTranslation("address", invoice.language)} : </span>
              <span className="text-gray-500">
                {[
                  invoice.Organization?.billingAddressStreet,
                  invoice.Organization?.billingAddressZipCode,
                  invoice.Organization?.billingAddressCity,
                  invoice.Organization?.billingAddressCountry,
                ]
                  .filter((address) => address)
                  .join(", ")}
              </span>
            </p>
          )}
          {invoice.Organization?.email && (
            <p className="text-sm py-1 text-left">
              <span> {t("email")} : </span>
              <span className="text-gray-500">{invoice.Organization?.email}</span>
            </p>
          )}
          {invoice.Organization?.registrationNumber && (
            <p className="text-sm py-1 text-left">
              <span>{getTranslation("registration_number", invoice.language)} : </span>
              <span className="text-gray-500">{invoice.Organization?.registrationNumber}</span>
            </p>
          )}
          {invoice.Organization?.vat && (
            <p className="text-sm py-1 text-left">
              <span> {getTranslation("vat_number", invoice.language)} : </span>
              <span className="text-gray-500">{invoice.Organization?.vat}</span>
            </p>
          )}
          {invoice.Organization?.iban && (
            <p className="text-sm py-1 text-left">
              <span>{t("iban")} : </span>
              <span className="text-gray-500">{invoice.Organization?.iban}</span>
            </p>
          )}
          {missingInformation && (
            <button
              onClick={() => setOpenOrganizationParam(true)}
              className="flex justify-center items-center gap-2 px-2.5 mt-2 py-1.5 text-xs font-medium rounded-md text-black bg-blue-300/20 hover:bg-blue-300/30 border border-blue-300 transition-colors">
              <span>{t("invoices.editor.complete_organization_info")}</span>
              <MdEdit size={14} />
            </button>
          )}
        </div>
      </header>
      <CreateClient
        open={showCreateClient}
        onCreated={(data) => {
          getAllClients();
          setSelectedClient(data);
          setInvoice({ ...invoice, ClientId: data._id });
        }}
        onClose={() => setShowCreateClient(false)}
      />
      <EditClient
        client={selectedClient}
        setClient={() => {
          setSelectedClient(null);
        }}
        onSave={(newClient) => {
          setInvoice({ ...invoice, ClientId: newClient?._id || null, Client: newClient || null, recipients: [...(newClient?.emails ?? [])] });
        }}
      />
      <UpdateOrganization organization={invoice.Organization} open={openOrganizationParams} onClose={() => setOpenOrganizationParam(false)} onSave={updateAndGetInvoice} />
    </div>
  );
};

const InvoiceDates = ({ invoice, setInvoice }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-8 mt-3 mb-5">
      <div className="flex flex-col gap-1">
        <span className="flex items-center gap-2">{t("sent_at")}</span>
        <input
          onChange={(evt) => setInvoice({ ...invoice, sentAt: evt.target.value ? new Date(evt.target.value) : new Date() })}
          type="date"
          value={new Date(invoice.sentAt).toISOString().slice(0, 10)}
          placeholder="dd-mm-yyyy"
          className="border border-gray-200 rounded-md px-2 py-1 w-48"
        />
      </div>
      <div className="flex flex-col gap-1">
        <span className="flex items-center gap-2">{getTranslation("due_date", invoice.language)}</span>
        <input
          onChange={(evt) => setInvoice({ ...invoice, dueAt: evt.target.value ? new Date(evt.target.value) : new Date() })}
          type="date"
          value={new Date(invoice.dueAt).toISOString().slice(0, 10)}
          placeholder="dd-mm-yyyy"
          className="border border-gray-200 rounded-md px-2 py-1 w-48"
        />
      </div>
    </div>
  );
};

const AmountTable = ({ invoice, setInvoice, taxOptions }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const PRODUCTS_PER_PAGE = 22;
  const FIRST_BREAKPOINT_THRESHOLD_LOWER = 11;
  const FIRST_BREAKPOINT_THRESHOLD_UPPER = 16;
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [createdProduct, setCreatedProduct] = useState(null);
  const [createdProductIndex, setCreatedProductIndex] = useState(null);
  const organization = useSelector((state) => state.Auth.organization);
  const navigate = useNavigate();

  const getProducts = async () => {
    try {
      const req = await api.post("/product/search", { OrganizationId: organization._id, per_page: 1000 });
      if (!req.ok) throw req;
      if (!req.data.total) throw new Error("No product found");
      setProducts(req.data.products);
    } catch (e) {
      console.error(t("toast.error"), e);
    }
  };

  useEffect(() => {
    if (!organization) return;
    getProducts();
  }, [organization]);

  useEffect(() => {
    if (createdProduct && createdProductIndex >= 0) {
      handleProductChange(createdProductIndex, {
        ProductId: createdProduct._id,
        InvoiceId: invoice._id,
        quantity: createdProduct.quantity,
        unit: createdProduct.unit,
        price: createdProduct.price,
        currency: createdProduct.currency,
        total: createdProduct.total,
        name: createdProduct.name,
        description: createdProduct.description,
        category: createdProduct.category,
        discount: createdProduct.discount,
        taxRate: createdProduct.taxRate,
        taxValue: 0,
      });
    }
  }, [createdProduct]);

  const handleProductChange = (index, invoiceProduct) => {
    const newProducts = [...invoice.InvoiceProducts];
    newProducts[index] = invoiceProduct;
    setInvoice({
      ...invoice,
      InvoiceProducts: newProducts,
    });
  };

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  const addProduct = () => {
    setInvoice({
      ...invoice,
      InvoiceProducts: [
        ...invoice.InvoiceProducts,
        {
          ProductId: null,
          InvoiceId: invoice._id,
          quantity: null,
          unit: null,
          price: null,
          currency: null,
          total: null,
          description: null,
          category: null,
          discount: null,
          taxType: null,
          taxRate: 0,
          TaxId: null,
          taxValue: 0,
          totalTaxIncluded: 0,
          totalTaxExcluded: 0,
          subTotal: 0,
        },
      ],
    });
  };

  const removeProduct = (index) => {
    if (!window.confirm(t("confirm_delete_message"))) return;
    setInvoice({
      ...invoice,
      InvoiceProducts: [...invoice.InvoiceProducts.slice(0, index), ...invoice.InvoiceProducts.slice(index + 1)],
    });
  };

  return (
    <div className="flex flex-col mt-3">
      <table className="w-full">
        <thead
          style={{
            background: hexToRgb(invoice.Organization.color || "#82c9de", 0.2),
            borderTop: `1px solid ${invoice.Organization.color || "#82c9de"}`,
            borderBottom: `1px solid ${invoice.Organization.color || "#82c9de"}`,
          }}>
          <tr className="text-sm text-black">
            <th className="py-3 pl-3 text-sm font-medium w-3/12"> {getTranslation("name", invoice.language)} </th>
            <th className="py-3 pr-2 text-sm text-left font-medium w-32"> {getTranslation("quantity", invoice.language)} </th>
            <th className="py-3 pr-2 text-sm text-left font-medium whitespace-nowrap w-32"> {getTranslation("unit_price", invoice.language)} </th>
            <th className="py-3 text-sm text-left font-medium whitespace-nowrap">{getTranslation("tax", invoice.language)} %</th>
            <th className="py-3 pl-2 text-sm text-left font-medium whitespace-nowrap w-1/12"> {getTranslation("tax", invoice.language)} €</th>
            <th className="py-3 pr-2 text-sm text-right font-medium whitespace-nowrap w-2/12"> {getTranslation("products_edit_price_ht", invoice.language)} </th>
            <th className="py-3 whitespace-nowrap w-8"></th>
          </tr>
        </thead>
        <tbody>
          {invoice.InvoiceProducts.map((invoiceProduct, index) => {
            const key = invoiceProduct._id ? `${invoiceProduct._id}` : index;
            const current = index + 1;

            const nbProducts = invoice.InvoiceProducts.length;
            const firstBreakPoint =
              nbProducts > FIRST_BREAKPOINT_THRESHOLD_LOWER + 1 && nbProducts < FIRST_BREAKPOINT_THRESHOLD_UPPER + 1 ? nbProducts : FIRST_BREAKPOINT_THRESHOLD_UPPER;

            let breakPage = current % (PRODUCTS_PER_PAGE + firstBreakPoint) === 0;
            const firstPage = current === firstBreakPoint;

            if (firstPage) {
              breakPage = current % firstBreakPoint === 0;
            }

            return (
              <React.Fragment key={key}>
                <tr className=" text-sm text-black text-left pb-10 break-inside-avoid align-top [&>td]:pt-5" key={key}>
                  <td className="whitespace-nowrap pl-3 pr-2">
                    <div className="flex flex-col h-full gap-2">
                      <Combobox
                        options={products}
                        value={products.find((product) => product._id === invoiceProduct?.ProductId)}
                        onChange={(product) => {
                          if (!product) return;
                          handleProductChange(index, {
                            ProductId: product._id,
                            InvoiceId: invoice._id,
                            quantity: product.quantity,
                            unit: product.unit,
                            price: product.price,
                            currency: product.currency,
                            total: product.total,
                            name: product.name,
                            description: product.description,
                            category: product.category,
                            discount: product.discount,
                            taxRate: product.taxRate,
                            TaxId: product.TaxId,
                            taxValue: 0,
                          });
                        }}
                        listHeader={
                          <button
                            onClick={() => {
                              setCreatedProductIndex(index);
                              setShowCreateProduct(true);
                            }}
                            className=" w-full bg-black-100 text-white px-3 py-2 flex items-center justify-between font-semibold text-sm">
                            {t("products.create_a_product")} <span>+</span>
                          </button>
                        }
                        inputClassName="py-2 pr-6"
                        containerClassName="rounded-none border-gray-200"
                        placeholder={t("product")}
                        getLabel={(product) => product.name}
                      />
                    </div>
                  </td>
                  <td className="text-left pr-2">
                    <input
                      type="number"
                      className="w-full border p-2 hover:border-sky-700"
                      value={invoiceProduct.quantity ?? 0}
                      onChange={(e) => {
                        handleProductChange(index, {
                          ...invoiceProduct,
                          quantity: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td className="text-left whitespace-nowrap pr-2">
                    <input
                      type="number"
                      className="w-full border p-2 hover:border-sky-700"
                      value={invoiceProduct.price ?? 0}
                      onChange={(e) => {
                        handleProductChange(index, {
                          ...invoiceProduct,
                          price: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td className="text-right w-44 whitespace-nowrap pr-2">
                    <Combobox
                      options={taxOptions}
                      value={taxOptions.find((value) => value._id === invoiceProduct.TaxId)}
                      onChange={(value) => {
                        if (!value) return;
                        handleProductChange(index, {
                          ...invoiceProduct,
                          TaxId: value._id,
                        });
                      }}
                      inputClassName="py-2 text-md"
                      listHeader={
                        <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs" onClick={navigateToOrgSettings}>
                          {t("add_vat")} <span>+</span>
                        </button>
                      }
                      containerClassName="rounded-none border-gray-200"
                      placeholder="Type"
                      getLabel={(tax) => tax.name}
                    />
                  </td>
                  <td className={`text-left text-gray-500 whitespace-nowrap align-middle pl-2`}>{invoiceProduct.taxValue ?? 0}</td>
                  <td className="text-right text-gray-500 whitespace-nowrap align-middle pr-2">{invoiceProduct.subTotal ?? 0}</td>
                  <td className="text-right whitespace-nowrap align-middle">
                    <button className="bg-transparent ml-2 font-semibold hover:text-red-500 hover:border-red-500 p-2 text-sm border rounded" onClick={() => removeProduct(index)}>
                      <LuTrash2 size={18} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="pl-3 pr-2 py-2">
                    <textarea
                      type="text"
                      className="w-full text-sm border px-2 py-2 mt-1"
                      placeholder="Description"
                      value={invoiceProduct.description ?? ""}
                      onChange={(e) => {
                        handleProductChange(index, {
                          ...invoiceProduct,
                          description: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <hr />
      <div className="flex justify-end">
        <button
          className="mb-5 mt-5 w-36 hover:bg-blue-300/30 text-black font-semibold py-2 px-2 text-sm border border-blue-300 bg-blue-300/20 rounded"
          onClick={() => addProduct()}>
          {t("invoices.editor.add_product")}
        </button>
      </div>
      <CreateProduct
        open={showCreateProduct}
        onClose={() => setShowCreateProduct(false)}
        onCreated={(data) => {
          getProducts();
          setCreatedProduct({ ...data });
        }}
      />
    </div>
  );
};

const SummaryTable = ({ invoice, setInvoice, taxOptions }) => {
  const { t } = useTranslation();
  const currency = currencyToStr(invoice.currency);
  const navigate = useNavigate();

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  return (
    <div className="flex justify-end mt-4">
      <div className="w-80">
        <table className="w-full table-fixed">
          <thead
            style={{
              background: hexToRgb(invoice.Organization.color || "#82c9de", 0.2),
              borderTop: `1px solid ${invoice.Organization.color || "#82c9de"}`,
              borderBottom: `1px solid ${invoice.Organization.color || "#82c9de"}`,
            }}>
            <tr className="text-black">
              <th className="py-3 text-center font-medium text-base" colSpan={2}>
                {getTranslation("summary", invoice.language)}
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-black">
            <tr className="border-b border-b-[#C9C8C9]">
              <td className="py-2.5 pl-3"> {getTranslation("sub_total", invoice.language)} </td>
              <td className="py-2.5 text-right pr-3">
                {invoice.subTotal}
                {currency}
              </td>
            </tr>
            <tr className="border-b border-b-[#C9C8C9]">
              <td className="py-2.5 pl-3">
                <div className="flex justify-between align-middle items-center">
                  <span>{getTranslation("discount", invoice.language)}</span>
                  <div>
                    <input
                      name="discount"
                      type="number"
                      value={invoice.discount ?? ""}
                      onChange={(e) => setInvoice({ ...invoice, discount: e.target.value })}
                      className="border-[1px] border-gray-200 placeholder:text-gray-300 rounded-lg w-16 h-7 px-2"
                      placeholder="10"
                    />
                    <span className="ml-1">%</span>
                  </div>
                </div>
              </td>
              <td className="py-2.5 text-right pr-3">{invoice.discount ? parseFloat(-(invoice.subTotal ?? 0) * (parseFloat(invoice.discount ?? 0) / 100)).toFixed(2) : 0}</td>
            </tr>
            {invoice.discount > 0 && (
              <tr className="border-b border-b-[#C9C8C9]">
                <td className="py-2.5 pl-3">Total excl. tax</td>
                <td className="py-2.5 text-right pr-3">
                  {parseFloat(invoice.totalTaxExcluded).toFixed(2)}
                  {currency}
                </td>
              </tr>
            )}
            <tr className="border-b border-b-[#C9C8C9]">
              <td className="py-2.5 pl-3 flex gap-2 items-center">
                <div>{getTranslation("tax", invoice.language)}</div>
                <div>
                  <Combobox
                    options={taxOptions}
                    value={taxOptions.find((value) => value._id === invoice.TaxId)}
                    onChange={(value) => setInvoice({ ...invoice, TaxId: value._id || null })}
                    width="w-44"
                    listHeader={
                      <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs" onClick={navigateToOrgSettings}>
                        {t("add_vat")} <span>+</span>
                      </button>
                    }
                    placeholder="Type"
                    getLabel={(tax) => tax.name}
                  />
                </div>
              </td>
              <td className="py-2.5 text-right pr-3">
                {invoice.taxValue}
                {currency}
              </td>
            </tr>
            <tr className="border-b border-b-[#C9C8C9]">
              <td className="py-2.5 pl-3"> {getTranslation("invoices_editor_total_incl_tax", invoice.language)} </td>
              <td className="py-2.5 text-right pr-3">
                {invoice.totalTaxIncluded}
                {currency}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const clientorSupplierOption = (item) => {
  if (!item) return null;
  return item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName;
};

const PreviewModal = ({ isOpen, invoice, onClose }) => {
  return (
    <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full h-[calc(100vh-5rem)] max-h-[calc(100vh-5rem)]" onClose={onClose}>
      <iframe src={invoice.file} width="100%" height="100%" />
    </Modal>
  );
};

export default Editor;
