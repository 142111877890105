import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaFileInvoice } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";

import categories from "@data/categories.js";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import { Combobox } from "@components/utils/Combobox";
import FileInputBtn from "@components/utils/FileInputBtn";
import { Select } from "@components/utils/Select";
import Loader from "@components/utils/Loader";
import { classNames, currencyToStr, getTransactionAccountName } from "@utils";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";
import ThirdPartySelection from "./thirdPartySelection";

import StripeLogo from "@assets/stripe-logo.png";
import { MdArrowRight } from "react-icons/md";
import { FaCashRegister, FaMoneyBill } from "react-icons/fa6";

const Transaction = ({
  transaction,
  index,
  setSelectedTransactions,
  selectedTransactions,
  updateTransaction,
  clientOptions,
  supplierOptions,
  employeeOptions,
  accountOptions,
  setLoading,
  loading,
  onSave,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const [open, setOpen] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState(categories);

  const isBundledTransactions = transaction?.StripeTransaction?.ReversePayoutTransaction?.length || transaction?.category === "2100";
  const isTransactionSelected = selectedTransactions?.some((t) => t._id === transaction._id);
  const showAiSuggestions = ["a4f2f18b-baf3-49f8-a3a7-e922e1cd98c0", "8f0ca818-7142-4f47-be67-3ba00f44277e"].includes(organization?._id);

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  const handleReconcile = async (e, transaction) => {
    e.preventDefault();
    setLoading((prev) => ({
      ...prev,
      [transaction._id]: true,
    }));
    try {
      if (!window.confirm("Are you sure you want to reconcile this transaction?")) return;

      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction, status: "RECONCILED" });
      if (!ok) throw { code };

      if (transaction.Invoices) {
        transaction.Invoices.forEach(async (invoice) => {
          await api.put(`/invoice/${invoice._id}`, { status: "PAID" });
        });
      }

      if (selectedTransactions.find((t) => t._id === transaction._id)) {
        setSelectedTransactions(selectedTransactions.filter((t) => t._id !== transaction._id));
      }
      toast.success(t("toast.success.reconciled"));
      onSave();
    } catch (e) {
      toast.error(e.code);
    } finally {
      setLoading((prev) => ({
        ...prev,
        [transaction._id]: false,
      }));
    }
  };

  const canReconcile = (transaction) => {
    if (!transaction.category || !transaction.type) return false;
    if (transaction.type === "CLIENT" && !transaction.ClientId && !transaction.ClientIdSuggestion) return false;
    if (transaction.type === "SUPPLIER" && !transaction.SupplierId && !transaction.SupplierIdSuggestion) return false;
    if (transaction.type === "EMPLOYEE" && !transaction.EmployeeId && !transaction.EmployeeIdSuggestion) return false;
    if (transaction.type === "TRANSFER" && !transaction.TransferAccountId) return false;
    return true;
  };

  const handleInvoiceUpload = async (file) => {
    if (!file) return;
    setLoading((prev) => ({
      ...prev,
      [transaction._id]: true,
    }));
    try {
      const { data } = await api.post("/invoice", {
        reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
        status: "DUE",
        source: "EXTERNAL",
        file: file.target.value,
      });
      updateTransaction({
        params: { ...transaction, Invoices: [data, ...transaction.Invoices] },
        needTransactionsUpdate: true,
      });
    } catch (error) {
      console.error("Error uploading invoice:", error);
      toast.error("Failed to upload invoice");
    } finally {
      setLoading((prev) => ({
        ...prev,
        [transaction._id]: false,
      }));
    }
  };

  const handlePurchaseInvoiceUpload = async (file) => {
    if (!file) return;
    setLoading((prev) => ({
      ...prev,
      [transaction._id]: true,
    }));
    try {
      const { data } = await api.post("/purchase-invoice", {
        reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
        file: file.target.value,
      });
      updateTransaction({
        params: { ...transaction, PurchaseInvoices: [data, ...transaction.PurchaseInvoices] },
        needTransactionsUpdate: true,
      });
    } catch (error) {
      console.error("Error uploading purchase invoice:", error);
      toast.error("Failed to upload purchase invoice");
    } finally {
      setLoading((prev) => ({
        ...prev,
        [transaction._id]: false,
      }));
    }
  };

  return (
    <>
      {loading[transaction._id] && (
        <tr className="absolute flex items-center justify-center w-full h-16">
          <td colSpan="10" className="flex gap-2">
            <Loader size={"small"} />
          </td>
        </tr>
      )}

      <tr
        onClick={() => {
          if (isBundledTransactions) return setOpen((prev) => !prev);
          if (isTransactionSelected) return setSelectedTransactions(selectedTransactions.filter((t) => t._id !== transaction._id));
          return setSelectedTransactions([...selectedTransactions, transaction]);
        }}
        key={transaction._id}
        className={classNames(
          "text-xs",
          isTransactionSelected ? "bg-blue-200" : index % 2 === 1 ? "bg-white hover:bg-blue-100" : "bg-gray-100 hover:bg-blue-100",
          loading[transaction._id] ? "opacity-40" : "opacity-100",
        )}>
        <td className={`whitespace-nowrap px-2.5`}>
          {isBundledTransactions ? (
            <div
              className="flex items-center gap-1 underline text-gray-600 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setOpen((prev) => !prev);
              }}>
              <MdArrowRight className={classNames("text-3xl transition duration-100 ease-in-out", open ? "rotate-90" : "rotate-0")} />
            </div>
          ) : (
            <input readOnly type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500" checked={isTransactionSelected} />
          )}
        </td>

        <td className={`w-24 px-2 py-2`}>
          <div className="inline-block">{transaction.date.slice(0, 10)}</div>
        </td>

        <td className={`px-1 w-64 py-2 whitespace-wrap`}>
          <div
            className="cursor-pointer underline font-bold"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/transactions?id=${transaction._id}`);
            }}>
            {(transaction.name || transaction.description || transaction.additionalInformation)?.length > 50
              ? (transaction.name || transaction.description || transaction.additionalInformation).substring(0, 47) + "..."
              : transaction.name || transaction.description || transaction.additionalInformation}
          </div>
        </td>

        <td className={` w-40  px-2 py-2`}>
          <div className="flex items-center gap-1 w-fit">
            <TransactionIcon transaction={transaction} />
            <div>{getTransactionAccountName(transaction)}</div>
          </div>
        </td>

        <td className={`max-w-[8rem] relative px-1 py-2`}>
          {transaction.category !== "2100" && (
            <div className="relative">
              <Select
                options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
                value={transaction.type}
                onChange={async (type) => {
                  const body = { type };
                  if (type === "TRANSFER") {
                    body.category = "8080";
                  }
                  updateTransaction({ params: { ...transaction, ...body } });
                }}
                getLabel={(e) => t(e.toLowerCase())}
                placeholder={t("type")}
                nullable={false}
                width="w-32"
              />
              {showAiSuggestions && transaction.aiType && (
                <div className="absolute w-full text-center text-[10px] leading-none">
                  AI : {t(transaction.aiType.toLowerCase())} - {Math.round(transaction.aiTypeConfidence * 100)}%
                </div>
              )}
            </div>
          )}
        </td>

        <td className={`max-w-[8rem] px-1 py-2`}>
          <ThirdPartySelection
            transaction={transaction}
            updateTransaction={updateTransaction}
            supplierOptions={supplierOptions}
            clientOptions={clientOptions}
            employeeOptions={employeeOptions}
            accountOptions={accountOptions}
          />
        </td>

        <td className={`max-w-[8rem] px-1 py-2`}>
          <div className="relative">
            <Combobox
              value={categories.find((category) => category.id == transaction.category)}
              options={filteredCategories}
              onChange={async (category) => {
                updateTransaction({ params: { ...transaction, category: category?.id || null }, needTransactionsUpdate: true });
              }}
              getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
              displayValue={(e) => {
                if (!e) return null;
                return t(`transactions.${e.id}`);
              }}
              placeholder={t("category")}
              width="w-96"
            />
            {showAiSuggestions && transaction.aiCategory && (
              <div className="absolute w-full text-center text-[10px] leading-none">
                AI : {transaction.aiCategory} - {Math.round(transaction.aiCategoryConfidence * 100)}%
              </div>
            )}
          </div>
        </td>

        <td className={`px-1 whitespace-nowrap gap-2 m-auto mx-0 w-6`}>
          <div className="flex justify-end items-center gap-1  ">
            {transaction.PurchaseInvoices.length > 0 && (
              <>
                {transaction.PurchaseInvoices.slice(-1).map((invoice, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <a href={invoice.file} target="_blank" rel="noopener noreferrer" title={invoice.fileName}>
                      <FaFileInvoice size={17} />
                      <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-white group-hover:scale-100">{invoice.reference}</span>
                    </a>
                  </div>
                ))}
                {transaction.PurchaseInvoices.length > 1 && (
                  <div className="flex justify-center items-center">
                    <span className="text-gray-500">+ {transaction.PurchaseInvoices.length - 1} </span>
                  </div>
                )}
              </>
            )}

            {transaction.Invoices.length > 0 && (
              <>
                {transaction.Invoices.slice(-1).map((invoice, index) => (
                  <div key={index} className="flex justify-center items-center">
                    <a href={invoice.file} target="_blank" rel="noopener noreferrer" title={invoice.fileName}>
                      <FaFileInvoice size={17} />
                      <span className="absolute top-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{invoice.reference}</span>
                    </a>
                  </div>
                ))}
                {transaction.Invoices.length > 1 && (
                  <div className="flex justify-center items-center">
                    <span className="text-gray-500">+ {transaction.Invoices.length - 1} </span>
                  </div>
                )}
              </>
            )}

            {transaction.amount > 0 ? (
              <FileInputBtn onChange={handleInvoiceUpload} name={transaction._id} folder="Invoices" />
            ) : transaction.amount < 0 ? (
              <FileInputBtn onChange={handlePurchaseInvoiceUpload} name={transaction._id} folder="PurchaseInvoices" />
            ) : null}
          </div>
        </td>

        <td className={` w-6 px-1 py-2 whitespace-nowrap`}>
          <div className=" flex items-center gap-1">
            <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
          </div>
        </td>

        <td className={`pl-2 pr-4 py-2 whitespace-nowrap right-0`}>
          <button
            className="px-2 font-semibold text-xs bg-black text-white h-8 rounded-lg disabled:opacity-50 w-full"
            onClick={(e) => {
              e.stopPropagation();
              handleReconcile(e, transaction);
              Mixpanel.track(MIXPANEL_EVENTS.btn_reconcile_transactions);
            }}
            disabled={!canReconcile(transaction)}
            data-tooltip-id={`button-tooltip-${transaction._id}`}
            data-tooltip-content={canReconcile(transaction) ? "" : t("transaction.reconcile_disable")}>
            {t("reconcile")}
          </button>
          <Tooltip id={`button-tooltip-${transaction._id}`} style={{ zIndex: 10, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
        </td>
      </tr>

      {open && !transaction?.StripeTransaction?.ReversePayoutTransaction?.length && (
        <tr className="hover:bg-gray-100 border-y border-gray-200">
          <td colSpan={10} className="whitespace-nowrap px-2.5 border-l p-2 text-center">
            {t("no_data")}
          </td>
        </tr>
      )}

      {open &&
        transaction?.StripeTransaction?.ReversePayoutTransaction?.map((subTransaction) => {
          return (
            <tr key={subTransaction.id} className="text-xs hover:bg-gray-100 border-y border-gray-200">
              <td className={`whitespace-nowrap px-2.5 text-sm border-l`}></td>
              <td className={`w-24 px-2 py-2 text-sm border-l`}>
                <div className="inline-block">{subTransaction.created.slice(0, 10)}</div>
              </td>
              <td>
                <div className="flex items-center gap-1 w-fit">
                  <img src={StripeLogo} height={20} width={20} className="mr-2" />
                  <div> {subTransaction.description}</div>
                </div>
              </td>
              <td />
              <td />
              <td>
                <div className="flex flex-col">
                  <span>
                    {subTransaction?.Client?.firstname} {subTransaction?.Client?.lastname}
                  </span>
                  <span>{subTransaction?.Client?.companyName ? `${subTransaction?.Client?.companyName}` : ""}</span>
                </div>
              </td>
              <td>{t(`stripe_transaction.status.${subTransaction?.type?.toUpperCase()}`)}</td>
              <td />
              <td className="w-full">
                <SubAmount amount={subTransaction.amount} fee={subTransaction.fee} currency={currencyToStr(subTransaction.currency)} />
              </td>
              <td className={`w-24 px-1 py-2 whitespace-nowrap border-r  border-l  right-0`}></td>
            </tr>
          );
        })}
    </>
  );
};

// todo if stripe payout, display a group of transactions, else display normal transactions
// duplicates children dans transactions ?
// mettre les payout avec un code comptable spécifique, pour les exclures stats

const Amount = ({ amount, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`px-2 py-1 rounded-sm flex justify-center items-center font-bold ${color}`}>
      {amount}
      {currency}
    </div>
  );
};

const SubAmount = ({ amount, fee, currency }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return (
    <div className={`px-2 py-1 rounded-sm flex flex-col justify-center items-end text-sm ${color}`}>
      <span className={color}>
        {amount}
        {currency}
      </span>
      <span className="text-red-700 text-xs">
        -{fee}
        {currency}
      </span>
    </div>
  );
};

const TransactionIcon = ({ transaction }) => {
  if (transaction.accountDetails === "petty_cash") return <FaCashRegister size={20} className="mr-2" />;
  if (transaction.accountDetails === "savings") return <FaMoneyBill size={20} className="mr-2" />;
  if (transaction.bankLogo) return <img src={transaction.bankLogo} height={20} width={20} className="mr-2" />;
};

export default Transaction;
