import categories from "@data/categories.js";
import api from "@services/api";
import { Mixpanel } from "@services/mixpanel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { classNames, currencyToStr, getAccountName } from "@utils";
import CreateTransaction from "@components/modals/createTransaction";
import { Combobox } from "@components/utils/Combobox";
import Loader from "@components/utils/Loader";
import Modal from "@components/utils/Modal";
import { SearchBar } from "@components/utils/SearchBar";
import { Select } from "@components/utils/Select";

import { Pagination } from "./pagination";
import EditTransactionModal from "./editTransactionModal";
import AllTransactions from "./allTransactions";
import TransactionsToReconcile from "./transactionsToReconcile";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import MultipleActionBar from "./multipleActionBar";

import { HiOutlineSparkles } from "react-icons/hi2";
import { FaCashRegister, FaMoneyBill } from "react-icons/fa6";

const ALL = "ALL";
const TO_RECONCILE = "TO_RECONCILE";

const List = () => {
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const [transactions, setTransactions] = useState([]);
  const [transactionsToReconcile, setTransactionsToReconcile] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalToReconcile, setTotalToReconcile] = useState(0);
  const [tab, setTab] = useState(ALL);
  const [filter, setFilter] = useState({ per_page: 20, page: 1, search: "", account: null, category: null, type: "" });
  const [accountOptions, setAccountOptions] = useState([]);
  const [clientOptions, setClientsOptions] = useState([]);
  const [supplierOptions, setSuppliersOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [open, setOpen] = useState(query.get("id"));
  const [openBankAccountToRenew, setOpenBankAccountToRenew] = useState(false);
  const [showCreateTransaction, setShowCreateTransaction] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  const navigate = useNavigate();

  const getTransactions = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
    });
    setTransactions(data.transactions);
    setTotal(data.total);
  };

  const getTransactionsToReconciled = async () => {
    const { data } = await api.post("/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      status: TO_RECONCILE,
      type: filter.type,
      accountId: filter.account?._id,
      category: filter.category?.id,
      ClientId: filter.ClientId,
      SupplierId: filter.SupplierId,
      OrganizationId: organization?._id,
      orderBy: "date",
      order: "DESC",
    });

    setTransactionsToReconcile(data.transactions);
    setTotalToReconcile(data.total);
  };

  useEffect(() => {
    if (!organization) return;
    const promises = [getTransactions(), getTransactionsToReconciled()];
    Promise.all(promises);
  }, [filter, organization]);

  const getAccounts = async () => {
    try {
      const { ok, data } = await api.post("/account/search", { OrganizationId: organization?._id, includePettyCash: true, includeSavings: true });
      if (!ok) {
        return toast.error(t("toast.error.fetching_accounts"));
      }
      if (data?.find((account) => ["EXPIRED", "ERROR_3", "SUSPENDED"].includes(account.status) && ["ACTIVE"].includes(account.Requisition.status))) {
        setOpenBankAccountToRenew(true);
      }
      setAccountOptions(data);
    } catch (error) {
      console.log("error getAccounts", error);
      toast.error(t("toast.error.fetching_accounts"));
    }
  };

  useEffect(() => {
    if (!organization) return;
    getAccounts();
    Mixpanel.track(MIXPANEL_EVENTS.page_view_transactions);
  }, [organization]);

  useEffect(() => {
    if (!organization) return;
    getClients();
    getSuppliers();
    getEmployees();
  }, [transactionsToReconcile]);

  const getClients = async () => {
    const { data } = await api.post("/client/search", { per_page: 1000, OrganizationId: organization?._id });
    setClientsOptions(data.clients);
  };
  const getSuppliers = async () => {
    const { data } = await api.post("/supplier/search", { per_page: 1000, OrganizationId: organization?._id });
    setSuppliersOptions(data.suppliers);
  };
  const getEmployees = async () => {
    const { data: employeeRes } = await api.post(`/employee/search`, { per_page: 1000 });
    setEmployeeOptions(employeeRes.employees);
  };

  useEffect(() => {
    if (query.get("id")) setOpen(true);
  }, [query]);

  if (!transactions) return <Loader />;

  return (
    <div className="mx-auto">
      <EditTransactionModal
        isOpen={open}
        onClose={() => {
          navigate("/transactions");
          setOpen(false);
        }}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
      />
      <CreateTransaction
        isOpen={showCreateTransaction}
        onClose={() => setShowCreateTransaction(false)}
        onSave={() => {
          getTransactionsToReconciled();
          getTransactions();
        }}
        accountOptions={accountOptions}
      />
      <RenewAccountModal
        isOpen={openBankAccountToRenew}
        onClose={() => {
          setOpen(false);
          setOpenBankAccountToRenew(false);
        }}
      />
      <div className="p-4">
        <div className="flex items-center justify-between p-2.5 mb-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg text-white">
          <span className="font-medium text-sm">{t("copilot.banner.title")}</span>
          <button
            onClick={() => {
              Mixpanel.track(MIXPANEL_EVENTS.btn_goto_copilot_from_transactions);
              navigate("/copilot");
            }}
            className="flex items-center gap-2 px-2.5 py-1 bg-white/20 rounded-full transition-all duration-300 hover:bg-white/30">
            <HiOutlineSparkles className="text-sm" />
            <span className="font-medium text-xs">{t("copilot.banner.button")}</span>

            <span className="text-xs bg-white/20 px-1.5 py-0.5 rounded-full">{t("copilot.banner.badge")}</span>
          </button>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col bg-white border border-gray-300 rounded-xl py-6 px-4">
            <div className="flex gap-3  justify-between">
              <div className="mb-5">
                <h1 className="text-2xl font-bold ">{t("transactions.title_page")}</h1>
                <p className="mt-2 text-slate-600">{t("transactions_intro")}</p>
              </div>
              <div>
                <button className="btn-primary focus:bg-blue float-right mb-2" onClick={() => setShowCreateTransaction(true)}>
                  {t("transactions.add_transaction")}
                </button>
              </div>
            </div>
            <div className="w-full flex flex-col gap-6">
              <div className="w-full">
                <label className="text-xs text-gray-500 capitalize">{t("search")}</label>
                <SearchBar search={filter.search} setFilter={setFilter} className="w-full text-lg" />
              </div>

              <div className="w-full grid grid-cols-1 lg:grid-cols-5 items-end gap-4">
                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("account")}</label>
                  <Select
                    options={accountOptions}
                    value={filter.account}
                    onChange={(e) => setFilter((f) => ({ ...f, account: e }))}
                    placeholder={t("account")}
                    getLabel={(account) => <AccountOption account={account} />}
                    width="w-full"
                    nullable={true}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("clients")}</label>
                  <Combobox
                    options={clientOptions}
                    value={clientOptions.find((client) => client._id === filter?.ClientId)}
                    onChange={async (e) => {
                      setFilter((f) => ({ ...f, ClientId: e._id }));
                    }}
                    placeholder={t("client")}
                    getLabel={(client) => {
                      if (client.entityType === "COMPANY") return client?.companyName || "";
                      return `${client?.firstname || ""} ${client?.lastname || ""}`;
                    }}
                    displayValue={(client) => {
                      if (!client) return null;
                      if (client.entityType === "COMPANY") return client?.companyName;
                      return `${client?.firstname || ""} ${client?.lastname || ""}`;
                    }}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("supplier")}</label>
                  <Combobox
                    options={supplierOptions}
                    value={supplierOptions.find((supplier) => supplier._id === filter?.SupplierId)}
                    onChange={async (e) => {
                      setFilter((f) => ({ ...f, SupplierId: e._id }));
                    }}
                    placeholder={t("supplier")}
                    getLabel={(supplier) => {
                      if (supplier.entityType === "COMPANY") return supplier?.companyName || "";
                      return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                    }}
                    displayValue={(supplier) => {
                      if (!supplier) return null;
                      if (supplier.entityType === "COMPANY") return supplier?.companyName;
                      return `${supplier?.firstname || ""} ${supplier?.lastname || ""}`;
                    }}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">{t("amount")}</label>
                  <Select
                    options={["INCOME", "EXPENSE"]}
                    value={filter.type}
                    onChange={(e) => setFilter((f) => ({ ...f, type: e }))}
                    placeholder={t("amount")}
                    getLabel={(type) => <span>{t(`transactions.${type}`)}</span>}
                    width="w-full"
                    nullable={true}
                    className="shadow-sm"
                  />
                </div>

                <div className="w-full">
                  <label className="text-xs font-medium text-gray-600 mb-1.5 block">
                    {t("categories")}
                    <Link
                      target="_blank"
                      to="/copilot"
                      className="text-gray-700 font-thin text-[10px] underline ml-1"
                      onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_learn_more_accounting_codes_list_transactions)}>
                      ({t("learn_more")})
                    </Link>
                  </label>
                  <Select
                    options={categories}
                    value={filter.category}
                    onChange={(e) => setFilter((f) => ({ ...f, category: e }))}
                    placeholder={t("category")}
                    getLabel={(category) => (category ? category.id + " - " + t(`transactions.${category.id}`) : null)}
                    width="w-full"
                    nullable={true}
                    className="shadow-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#f5f6fc]">
            <nav className="flex flex-row w-full justify-between gap-8 items-center my-2">
              <div className="flex p-2 w-fit gap-4 bg-white rounded-xl border border-gray-300">
                <TabItem
                  title={t("all")}
                  tab={ALL}
                  setTab={setTab}
                  active={tab === ALL}
                  RightComponent={<span className="ml-2 text-sm bg-blue-200 px-2 py-0.5 rounded-xl text-blue-500 border border-blue-500 font-medium">{total}</span>}
                />
                <TabItem
                  title={t("to_reconcile")}
                  tab={TO_RECONCILE}
                  setTab={setTab}
                  active={tab === TO_RECONCILE}
                  RightComponent={
                    <span className="ml-2 text-sm bg-orange-200 px-2 py-0.5 rounded-xl text-orange-500 border border-orange-500 font-medium">{totalToReconcile}</span>
                  }
                />
              </div>
              {selectedTransactions.length > 0 && tab === TO_RECONCILE && (
                <MultipleActionBar
                  supplierOptions={supplierOptions}
                  clientOptions={clientOptions}
                  employeeOptions={employeeOptions}
                  accountOptions={accountOptions}
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                  setLoadingTransaction={setLoadingTransaction}
                  onSave={() => {
                    getTransactionsToReconciled();
                    getTransactions();
                  }}
                />
              )}
            </nav>
          </div>

          <div className="w-full rounded-xl border border-gray-300 bg-white py-2">
            {tab === ALL && (
              <>
                <AllTransactions transactions={transactions} />
                <Pagination total={total} filter={filter} setFilter={setFilter} />
              </>
            )}
            {tab === TO_RECONCILE && (
              <>
                <TransactionsToReconcile
                  selectedTransactions={selectedTransactions}
                  setSelectedTransactions={setSelectedTransactions}
                  transactions={transactionsToReconcile}
                  loadingTransaction={loadingTransaction}
                  setLoadingTransaction={setLoadingTransaction}
                  onSave={() => {
                    getTransactionsToReconciled();
                    getTransactions();
                  }}
                />
                <Pagination total={totalToReconcile} filter={filter} setFilter={setFilter} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, RightComponent }) => {
  return (
    <button
      onClick={() => setTab(tab)}
      className={classNames(
        "flex-1 p-1 w-44 text-center text-sm font-medium transition-all duration-200 rounded-xl",
        active ? "text-blue-500 border-2 border-blue-500" : "text-gray-500 border-2 hover:border-blue-200 border-gray-200",
      )}>
      <div className="flex items-center justify-center gap-2">
        {title}
        {RightComponent}
      </div>
    </button>
  );
};

const AccountOption = ({ account }) => {
  if (!account) return;

  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash") return <FaCashRegister className="h-5 w-5" />;
  if (account.details === "savings") return <FaMoneyBill className="h-5 w-5" />;
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-5 w-5" />;
};

const RenewAccountModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onClose={onClose} innerClassName="flex max-w-[40rem] w-[40vw] max-h-[calc(100vh-5rem)]">
      <div className="flex flex-col items-center justify-center w-full p-5">
        <div className="mb-5">{t("myintegrations.need_reconnection")}</div>
        <button
          className="btn-primary w-max"
          onClick={(e) => {
            Mixpanel.track(MIXPANEL_EVENTS.btn_renew_account_requisition);
            navigate("/bank");
          }}>
          {t("myintegrations.renew_account")}
        </button>
      </div>
    </Modal>
  );
};

export default List;
