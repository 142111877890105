import api from "@services/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TbClockBolt } from "react-icons/tb";
import { MdBolt } from "react-icons/md";
import factureLogo from "../../assets/facture-logo.png";

import { statuses } from "@constants";
import { getClientName } from "@utils";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

import ActionsMenu from "@components/utils/ActionsMenu";
import TruncatedText from "@components/utils/TruncatedText";
import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";
import { Select } from "@components/utils/Select";

import CreateInvoice from "./createInvoice";
import Stats from "./stats";

const List = () => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState({ search: "", page: 1, per_page: 10, statuses: statuses.filter((status) => status !== "ARCHIVED") });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [hasInvoices, setHasInvoices] = useState(true);
  const navigate = useNavigate();

  const organization = useSelector((state) => state.Auth.organization);

  useEffect(() => {
    if (!organization) return;
    get();
  }, [organization, filter]);

  useEffect(() => {
    checkForExistingInvoices();
  }, [organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_invoices);
  }, []);

  const checkForExistingInvoices = async () => {
    try {
      const { data, ok } = await api.post("/invoice/search", {
        per_page: 1,
        page: 1,
        OrganizationId: organization._id,
        statuses: statuses,
      });
      if (!ok) throw new Error();
      setHasInvoices(data.total > 0);
    } catch (error) {
      console.error("Error checking existing invoices:", error);
      toast.error(t("toast.error"));
    }
  };

  async function get() {
    try {
      setLoading(true);
      const query = {
        per_page: filter.per_page,
        page: filter.page,
        search: filter.search,
        statuses: filter.statuses,
        OrganizationId: organization._id,
      };
      const { data, ok } = await api.post("/invoice/search", query);
      if (!ok) throw new Error();
      setInvoices(data.invoices);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      toast.error(t("toast.error"));
    } finally {
      setLoading(false);
    }
  }

  async function handleDuplicate(id) {
    const confirm = window.confirm(t("confirm_duplicate_message"));
    if (!confirm) return;
    try {
      const duplicateReq = await api.get(`/invoice/${id}/duplicate`);
      if (!duplicateReq.ok) throw duplicateReq.code;

      const invoice = duplicateReq.data;
      // update invoice name
      const { ok } = await api.put(`/invoice/${invoice._id}`, {
        name: t("invoices.editor.subject_default", { organization: organization.name, reference: invoice.reference }),
      });
      if (!ok) throw new Error();
      toast.success(t("toast.sucess.duplicated"));
      navigate(`/invoices/${invoice._id}`);
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  }
  async function handleArchive(id) {
    const confirm = window.confirm(t("confirm_message"));
    if (!confirm) return;
    try {
      const { ok } = await api.put(`/invoice/${id}`, { status: "ARCHIVED" });
      if (!ok) throw new Error();
      toast.success(t("toast.sucess.archived"));
      get();
    } catch (e) {
      console.error("Error archiving invoice:", e);
      toast.error(t("toast.error"));
    }
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("invoices")}</h1>
              <p className="mt-2 text-slate-600">{t("invoices.intro")}</p>
            </div>
            <div className="flex gap-2">
              <button className="bnt-primary focus:bg-blue float-right mb-2"> {t("import_from_csv")} </button>
              <CreateInvoice
                onCreated={(el) => {
                  get();
                  navigate(`/invoices/${el._id}`);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-5 mt-2">
            <Stats />

            {!hasInvoices ? (
              <div className="flex flex-col items-center justify-center py-12 px-4">
                <div className="flex justify-center items-center gap-12">
                  <div className="flex-shrink-0">
                    <img src={factureLogo} alt="Créez votre première facture" className="w-72" />
                  </div>
                  <div className="flex flex-col w-1/2">
                    <h2 className="text-2xl font-semibold mb-4">Créez votre première facture !</h2>
                    <p className="text-gray-600 mb-6">Nous vous avons préparé un modèle de facture</p>
                    <div className="space-y-3 text-gray-600 mb-8">
                      <div className="flex items-center gap-2">
                        <CheckIcon />
                        <span>Un modèle de facture standardisé</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon />
                        <span>L'entête avec les informations de votre entreprise automatiquement intégrées</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon />
                        <span>Couleur bleue signature</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon />
                        <span>Des taux de TVA configurables selon votre secteur d'activité</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon />
                        <span>Conditions de paiement prédéfinies</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <CheckIcon />
                        <span>Votre première facture commencera avec le numéro 1</span>
                      </div>
                    </div>
                    <p className="text-blue-500 mb-8">Prenez quelques minutes pour l'adapter à votre activité !</p>
                  </div>
                </div>
                <button className="btn-primary" onClick={() => navigate("/settings/my-organizations")}>
                  Personnaliser mes factures
                </button>
              </div>
            ) : (
              <>
                <div className="flex items-end space-x-6">
                  <SearchBar search={filter.search} setFilter={setFilter} />
                  <div className="w-full md:w-80">
                    <label className="text-xs text-gray-500"> {t("status")} </label>
                    <Select
                      options={statuses}
                      value={filter.statuses}
                      multiple
                      getLabel={(status) => t(`invoices.status.${status}`)}
                      onChange={(statuses) => setFilter({ ...filter, statuses })}
                    />
                  </div>
                </div>
                <Table invoices={invoices} onEdit={(invoice) => navigate(`/invoices/${invoice._id}`)} onArchive={handleArchive} onDuplicate={handleDuplicate} />
                <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckIcon = () => {
  return (
    <div className="bg-blue-50 rounded-full p-1">
      <svg className="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
    </div>
  );
};

const Table = ({ invoices, onEdit, onArchive, onDuplicate }) => {
  return (
    <div className="overflow-x-auto flow-root min-w-full align-middle overflow-hidden rounded-lg border">
      <table className="min-w-full">
        <thead className="border-b">
          <tr>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("status")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900  uppercase">
              {t("reference")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("sent_at")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("due_date")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-500   uppercase">
              {t("client")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-bold text-gray-500   uppercase">
              {t("vat")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-bold text-gray-500   uppercase">
              {t("total")}
            </th>
            <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-right text-sm font-semibold text-gray-500 uppercase">
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {invoices.map((invoice) => (
            <tr key={invoice._id}>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <div className="flex items-center gap-x-2 gap-y-1 w-fit">
                  <Status status={invoice.status} />
                  {invoice.source === "EXTERNAL" && <div className="gap-x-1.5 rounded-md text-xs text-gray-600 italic"> {t(`invoices.source.${invoice.source}`)}</div>}
                </div>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <span onClick={() => onEdit(invoice)} className="cursor-pointer underline font-bold ">
                  {(invoice.reference || "-").substring(0, 20)}
                </span>
                {Boolean(invoice.InvoiceRecurrence) && (
                  <div className="flex gap-1 items-center text-xs italic">
                    <TbClockBolt />
                    {t("invoices.list.next")}:&nbsp;{new Date(invoice.InvoiceRecurrence?.nextRunAt).toLocaleDateString().split("T")[0]}
                  </div>
                )}
                {invoice.source === "RECURRING" && (
                  <div className="flex gap-1 items-center text-xs italic">
                    <MdBolt />
                    {t("invoices.list.initial_invoice")}:&nbsp;
                    <Link className="underline" to={`/invoices/${invoice.sourceInvoiceId}`}>
                      {invoice.sourceInvoice?.reference}
                    </Link>
                  </div>
                )}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{invoice?.sentAt ? new Date(invoice.sentAt).toLocaleDateString().split("T")[0] : "-"}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{invoice?.dueAt ? new Date(invoice.dueAt).toLocaleDateString().split("T")[0] : "-"}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <TruncatedText str={getClientName(invoice.Client)} description={invoice.Client?.name} />
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right">{invoice?.taxValue}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 text-right">{invoice?.totalTaxIncluded}</td>
              <td className="whitespace-nowrap px-2 py-2 text-sm">
                <div className="flex justify-end w-full">
                  <ActionsMenu
                    actions={[
                      { label: t("edit"), callback: () => onEdit(invoice) },
                      { label: t("archive"), callback: () => onArchive(invoice._id) },
                      { label: t("duplicate"), callback: () => onDuplicate(invoice._id) },
                    ]}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Status = ({ status }) => {
  let bgColor = "bg-gray-100";
  let textColor = "text-gray-600";
  let dotColor = "bg-gray-400";
  if (status === "ARCHIVED") {
    bgColor = "bg-orange-100";
    textColor = "text-orange-600";
    dotColor = "bg-orange-400";
  }
  if (status === "DUE") {
    bgColor = "bg-purple-100";
    textColor = "text-purple-600";
    dotColor = "bg-purple-400";
  }
  if (status === "SENT") {
    bgColor = "bg-blue-100";
    textColor = "text-blue-600";
    dotColor = "bg-blue-400";
  }
  if (status === "PAID") {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
    dotColor = "bg-green-400";
  }
  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>
      <div className={`h-2 w-2 rounded-full ${dotColor}`} />
      {t(`invoices.status.${status}`)}
    </span>
  );
};

export default List;
