import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

import { currencyToStr, getAccountName } from "@utils";
import api from "@services/api";
import categories from "@data/categories.js";
import { Mixpanel } from "@services/mixpanel";
import Loader from "@components/utils/Loader";
import { Combobox } from "@components/utils/Combobox";
import FileInput from "@components/utils/FileInput";
import Modal from "@components/utils/Modal";
import { Select } from "@components/utils/Select";
import CreateClient from "@components/modals/createClient";
import CreateSupplier from "@components/modals/createSupplier";
import CreateEmployee from "@components/modals/createEmployee";

import TransactionsStripe from "./transactionsStripe";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

import { RxCross1 } from "react-icons/rx";
import { AiFillFileImage } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { FaCashRegister, FaRegFile } from "react-icons/fa6";
import { FaMoneyBill } from "react-icons/fa";

const EditTransactionModal = ({ isOpen, onClose, onSave }) => {
  const [transaction, setTransaction] = useState({});
  const { search } = useLocation();
  const [clientOptions, setClientOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const [showCreateSupplier, setShowCreateSupplier] = useState(false);
  const [showCreateEmployee, setShowCreateEmployee] = useState(false);
  const [linkedStripeTransactions, setLinkedStripeTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Auth);
  const navigate = useNavigate();

  const navigateToOrgSettings = () => {
    if (!window.confirm(t("confirm_unsaved_message"))) return;
    navigate("/settings/my-organizations");
  };

  const fetchTransaction = async (transactionId) => {
    const res = await api.get(`/transaction/${transactionId}`);
    if (!res.ok) toast.error(t("toast.error.fetching_trans"));
    if (res.data.linkedStripeTransactionId) {
      const { ok, data: stripeTransactions } = await api.get(`/stripe/transaction/payout/${res.data.linkedStripeTransactionId}?OrganizationId=${organization._id.toString()}`);
      if (!ok) toast.error(t("toast.error.fetching_stripe_trans"));
      setLinkedStripeTransactions(stripeTransactions.transactions);
    }
    if (!res.data.type) {
      res.data.type = res.data.amount < 0 ? "SUPPLIER" : "CLIENT";
    }
    setTransaction(res.data);
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const transactionId = query.get("id");
    if (!transactionId) return onClose();
    fetchTransaction(transactionId);
  }, [isOpen]);

  const getClients = async () => {
    const { data: clientRes } = await api.post(`/client/search`, { per_page: 1000, OrganizationId: organization?._id });
    setClientOptions(clientRes.clients);
  };

  const getSuppliers = async () => {
    const { data: supplierRes } = await api.post(`/supplier/search`, { per_page: 1000, OrganizationId: organization?._id });
    setSupplierOptions(supplierRes.suppliers);
  };

  const getEmployees = async () => {
    const { data: employeeRes } = await api.post(`/employee/search`, { per_page: 1000, OrganizationId: organization?._id });
    setEmployeeOptions(employeeRes.employees);
  };

  const getAccounts = async () => {
    try {
      const { ok, data } = await api.post("/account/search", { OrganizationId: organization?._id, includePettyCash: true, includeSavings: true });
      if (!ok) {
        return toast.error(t("toast.error.fetching_accounts"));
      }
      setAccountOptions(data);
    } catch (error) {
      console.log("error getAccounts", error);
      toast.error(t("toast.error.fetching_accounts"));
    }
  };

  useEffect(() => {
    getClients();
    getSuppliers();
    getEmployees();
    getAccounts();
  }, [transaction]);

  useEffect(() => {
    if (organization?.accountingCodes) {
      setFilteredCategories(categories.filter((category) => !organization.accountingCodes.includes(category.id)));
    }
  }, [organization]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.sucess.updated_sucessfully"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };
  const canReconcile = () => {
    return transaction.category && (transaction.ClientId || transaction.SupplierId || transaction.EmployeeId || transaction.TransferAccountId);
  };

  const handleSaveAndReconcile = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction, status: "RECONCILED" });
      if (transaction.Invoices) {
        transaction.Invoices.forEach(async (invoice) => {
          await api.put(`/invoice/${invoice._id}`, { status: "PAID" });
        });
      }
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.success.reconciled"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleCancelReconcile = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const { ok, code } = await api.put(`/transaction/${transaction._id}`, { ...transaction, status: "TO_RECONCILE" });
      if (!ok) throw { code };
      onSave();
      toast.success(t("toast.success.cancel_reconcile"));
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    try {
      const confirm = window.confirm(t("confirm_delete_message"));
      if (!confirm) return;
      const { ok, code } = await api.remove(`/transaction/${transaction._id}`);
      if (!ok) throw code;
      toast.success(t("toast.sucess.removed"));
      onSave();
      handleClose();
    } catch (e) {
      console.error(t("toast.error"), e);
      toast.error(e.code);
    }
  };

  const handleClose = () => {
    setTransaction(null);
    setLinkedStripeTransactions([]);
    onClose();
  };

  const supplierOption = (item) => {
    if (!item) return null;
    const supplierLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.SupplierId && transaction?.SupplierIdSuggestion === item._id) return `${supplierLabel} (${t("suggested")})`;
    return supplierLabel;
  };

  const clientOption = (item) => {
    if (!item) return null;
    const clientLabel = item.entityType === "INDIVIDUAL" ? item.firstname + " " + item.lastname : item.companyName || "";
    if (!transaction?.ClientId && transaction?.ClientIdSuggestion === item._id) return `${clientLabel} (${t("suggested")})`;
    return clientLabel;
  };

  if (loading) return <Loader />;
  if (!transaction) return null;

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} innerClassName="flex min-w-[50rem] max-w-[90vw] max-h-[calc(100vh-5rem)]">
        <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
          <div className="px-8 pt-8 pb-3 border-b flex justify-between">
            <div className="text-lg font-semibold">{t("transactions.edit_transaction")}</div>
            <button type="submit" onClick={handleClose}>
              <RxCross1 />
            </button>
          </div>
          <div className="py-4 px-8 overflow-auto">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                <div className="flex flex-col">
                  <div className="text-xs text-gray-500 "> {t("date")} </div>
                  <div className="placeholder:text-gray-300 pr-3 py-1">{new Date(transaction.date).toLocaleString()}</div>
                </div>
                <div className="flex flex-col">
                  <div className="text-xs text-gray-500 "> {t("description")}</div>
                  <div className="border-gray-300 placeholder:text-gray-300 pr-3 py-1">
                    {transaction.name}, {transaction.description}, {transaction.additionalInformation}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-xs text-gray-500 "> {t("account")} </div>
                  <div className="border-gray-300 placeholder:text-gray-300 pr-3 py-1">
                    <img src={transaction.bankLogo} height={20} width={20} className="mr-2" />
                    {transaction.accountDetails} {transaction.accountOwner}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-xs text-gray-500 "> {t("amount")} </div>
                  <div className=" border-gray-300 placeholder:text-gray-300 pr-3 py-1">
                    {transaction.amount} {currencyToStr(transaction.currency)}
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <label htmlFor="filteredCategories" className="text-xs text-gray-500 ">
                    {t("category")}
                    <Link
                      target="_blank"
                      to="/copilot"
                      className="text-gray-700 font-thin text-[10px] underline ml-1"
                      onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_learn_more_accounting_codes_edit_transactions)}>
                      ({t("learn_more")})
                    </Link>
                  </label>
                  <div className="placeholder:text-gray-300 py-1">
                    <Combobox
                      value={filteredCategories.find((category) => category.id == transaction.category)}
                      options={filteredCategories}
                      onChange={(category) => setTransaction({ ...transaction, category: category?.id || null })}
                      getLabel={(e) => (e ? e.id + " - " + t(`transactions.${e.id}`) : null)}
                      placeholder={t("transactions.edit.placeholder.select_category")}
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <label htmlFor="taxOptions" className="text-xs text-gray-500 ">
                    {t("vat")}
                  </label>

                  <div className="placeholder:text-gray-300 py-1">
                    <Combobox
                      options={organization.Taxes}
                      value={organization.Taxes.find((value) => value._id === transaction.TaxId)}
                      onChange={(value) => setTransaction({ ...transaction, TaxId: value._id || null })}
                      getLabel={(tax) => tax.name}
                      placeholder={t("vat")}
                      listHeader={
                        <button className="px-3 py-2 bg-black-100 text-white w-full flex items-center justify-between font-semibold text-xs" onClick={navigateToOrgSettings}>
                          {t("add_vat")} <span>+</span>
                        </button>
                      }
                    />
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <TransactionsStripe transactions={linkedStripeTransactions} />
              {transaction.category !== "2100" && (
                <div className="grid grid-cols-2 grid-rows-1 gap-4">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 "> {t("type")} </div>
                    <div className="placeholder:text-gray-300 py-1">
                      <Select
                        options={["CLIENT", "SUPPLIER", "EMPLOYEE", "TRANSFER"]}
                        value={transaction.type ? transaction.type : transaction.amount < 0 ? "SUPPLIER" : "CLIENT"}
                        onChange={(type) => {
                          const body = { type };
                          if (type === "TRANSFER") {
                            body.category = "8080";
                          }
                          setTransaction((transaction) => ({
                            ...transaction,
                            ...body,
                          }));
                        }}
                        getLabel={(e) => t(e.toLowerCase())}
                        placeholder="Select a type"
                        nullable={false}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    {transaction.type == "SUPPLIER" ? (
                      <>
                        <div className="text-xs text-gray-500 "> {t("supplier")} </div>
                        <div className="placeholder:text-gray-300 py-1">
                          <Combobox
                            options={supplierOptions}
                            value={supplierOptions.find((supplier) => supplier._id === (transaction?.SupplierId || transaction?.SupplierIdSuggestion))}
                            onChange={(e) =>
                              setTransaction((transaction) => ({
                                ...transaction,
                                SupplierId: e?._id || null,
                                category: transaction.category || e?.defaultCategory,
                              }))
                            }
                            placeholder={t("supplier")}
                            getLabel={(supplier) => supplierOption(supplier)}
                            nullable={true}
                            listHeader={
                              <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateSupplier(true)}>
                                {t("supplier.create_supplier")} <span>+</span>
                              </button>
                            }
                            displayValue={(supplier) => supplierOption(supplier)}
                          />
                        </div>
                      </>
                    ) : transaction.type == "EMPLOYEE" ? (
                      <>
                        <div className="text-xs text-gray-500 "> {t("employee")} </div>
                        <div className="placeholder:text-gray-300 py-1">
                          <Combobox
                            options={employeeOptions}
                            value={employeeOptions.find((employee) => employee._id === (transaction?.EmployeeId || transaction?.EmployeeIdSuggestion))}
                            onChange={(e) => {
                              setTransaction((transaction) => ({ ...transaction, EmployeeId: e?._id || null }));
                            }}
                            placeholder={t("employee")}
                            getLabel={(employee) =>
                              transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === employee._id
                                ? `${employee.firstname} ${employee.lastname} (${t("suggested")})`
                                : employee.firstname + " " + employee.lastname
                            }
                            listHeader={
                              <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateEmployee(true)}>
                                {t("employees.create")} <span>+</span>
                              </button>
                            }
                            displayValue={(employee) => {
                              if (!employee) return null;
                              const employeeLabel = employee.firstname + " " + employee.lastname;
                              if (!transaction?.EmployeeId && transaction?.EmployeeIdSuggestion === employee._id) return `${employeeLabel} (${t("suggested")})`;
                              return employeeLabel;
                            }}
                          />
                        </div>
                      </>
                    ) : transaction.type == "CLIENT" ? (
                      <>
                        <div className="text-xs text-gray-500 "> {t("client")} </div>
                        <div className="placeholder:text-gray-300 py-1">
                          <Combobox
                            options={clientOptions}
                            value={clientOptions.find((client) => client._id === (transaction?.ClientId || transaction?.ClientIdSuggestion))}
                            onChange={(e) => {
                              setTransaction((transaction) => ({
                                ...transaction,
                                ClientId: e?._id || null,
                                category: transaction.category || e?.defaultCategory,
                              }));
                            }}
                            placeholder={t("client")}
                            getLabel={(client) => clientOption(client)}
                            listHeader={
                              <button className="px-3 py-2 w-full flex items-center justify-between font-semibold text-sm" onClick={() => setShowCreateClient(true)}>
                                {t("clients.create_a_client")} <span>+</span>
                              </button>
                            }
                            displayValue={(client) => clientOption(client)}
                          />
                        </div>
                      </>
                    ) : transaction.type == "TRANSFER" ? (
                      <>
                        <div className="text-xs text-gray-500 "> {t("account")} </div>
                        <div className="placeholder:text-gray-300 py-1">
                          <Select
                            options={accountOptions}
                            value={accountOptions.find((account) => account._id == transaction.TransferAccountId) || null}
                            onChange={(e) => {
                              setTransaction((transaction) => ({
                                ...transaction,
                                TransferAccountId: e?._id || null,
                              }));
                            }}
                            placeholder={t("account")}
                            getLabel={(account) => <AccountOption account={account} />}
                            width="w-full"
                            nullable={true}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              )}

              {/* not payout and not transfer */}
              {!["2100", "8080"].includes(transaction.category) &&
                (transaction.type === "CLIENT" ? (
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 "> {t("sales_invoices")} </div>
                    <div className="py-1">
                      <TableAssociatedInvoices transaction={transaction} setTransaction={setTransaction} />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500"> {t("purchase_invoices")} </div>
                    <div className="py-1">
                      <TableAssociatedPurchaseInvoices transaction={transaction} setTransaction={setTransaction} />
                    </div>
                  </div>
                ))}
              <hr className="mb-2" />

              <div className="flex flex-col">
                <div className="text-xs text-gray-500"> {t("memo_note")} </div>
                <textarea
                  type="text"
                  value={transaction.memo ?? ""}
                  onChange={(e) => setTransaction({ ...transaction, memo: e.target.value })}
                  className="border-[1px] border-gray-300 placeholder:text-gray-300 rounded-lg px-3 py-2"
                  placeholder={t("memo_note.transaction.placeholder")}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-3 w-full mt-3 border-t py-3 px-8">
            <button
              className="btn-secondary w-max"
              onClick={(e) => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_updated);
                handleSubmit(e);
              }}>
              {t("save")}
            </button>
            {transaction.status === "TO_RECONCILE" ? (
              <>
                <button
                  disabled={!canReconcile()}
                  className="btn-primary w-max"
                  onClick={(e) => {
                    Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_reconciled);
                    handleSaveAndReconcile(e);
                  }}
                  data-tooltip-id={`button-tooltip`}
                  data-tooltip-content={canReconcile() ? "" : t("transaction.reconcile_disable")}>
                  {t("save_reconcile")}
                </button>
                <Tooltip id={`button-tooltip`} opacity={1} style={{ zIndex: 1000, backgroundColor: "#40a5c6", borderRadius: "6px" }} />
              </>
            ) : (
              <button
                className="btn-primary w-max"
                onClick={(e) => {
                  Mixpanel.track(MIXPANEL_EVENTS.btn_transaction_cancel_reconciled);
                  handleCancelReconcile(e);
                }}>
                {t("cancel_reconcile")}
              </button>
            )}
          </div>
        </div>
      </Modal>
      <CreateClient
        open={showCreateClient}
        onClose={() => {
          setShowCreateClient(false);
        }}
        onCreated={(data) => {
          setTransaction((transaction) => ({ ...transaction, ClientId: data?._id || null }));
          getClients();
        }}
      />
      <CreateSupplier
        open={showCreateSupplier}
        onClose={() => {
          setShowCreateSupplier(false);
        }}
        onCreated={(data) => {
          setTransaction((transaction) => ({ ...transaction, SupplierId: data?._id || null }));
          getSuppliers();
        }}
      />
      <CreateEmployee
        open={showCreateEmployee}
        onClose={() => {
          setShowCreateEmployee(false);
        }}
        onCreated={(data) => {
          setTransaction((transaction) => ({ ...transaction, EmployeeId: data?._id || null }));
          getEmployees();
        }}
      />
    </>
  );
};

const TableAssociatedInvoices = ({ transaction, setTransaction }) => {
  const [loading, setLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);

  const getInvoices = async () => {
    const { data } = await api.post("/invoice/search", { per_page: 1000, statuses: ["SENT", "PAID", "DUE"], OrganizationId: organization?._id });
    setInvoices(data.invoices);
  };

  const removeInvoice = (index) => {
    setTransaction({
      ...transaction,
      Invoices: [...transaction.Invoices.slice(0, index), ...transaction.Invoices.slice(index + 1)],
    });
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div className="flex w-full">
      <div className="w-full align-middle overflow-visible  flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <Select
              options={invoices}
              value={transaction?.Invoices || []}
              onChange={(selectedOptions) => {
                setTransaction({
                  ...transaction,
                  Invoices: selectedOptions,
                });
              }}
              multiple
              placeholder={t("invoice")}
              getLabel={(invoice) => {
                if (!invoice) return null;
                return (
                  <div className="flex justify-between w-full gap-1">
                    <div className="flex items-center">
                      <span className="text-sm">{invoice.reference}</span>
                      {invoice.Client && (
                        <div className="flex items-center gap-1">
                          <span>&nbsp; - Client : </span>
                          <span className="px-2 bg-purple-100">
                            {invoice.Client.entityType === "INDIVIDUAL" ? invoice.Client.firstname + " " + invoice.Client.lastname : invoice.Client.companyName || ""}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="px-2 bg-blue-100">{invoice.totalTaxIncluded} €</span>
                      {invoice.dueAt ? <span className="px-2 bg-green-100">{new Date(invoice.dueAt).toLocaleDateString()}</span> : null}
                    </div>
                  </div>
                );
              }}
              listHeader={
                <Link to={`/invoices?modal=open`} className="px-4 py-2 flex items-center justify-between font-semibold text-sm">
                  {t("invoices.create")} <span>+</span>
                </Link>
              }
              by={(a, b) => a._id === b._id}
            />
          </div>
          <div className="flex gap-3">
            <FileInput
              onChange={async (file) => {
                if (!file) return;
                setLoading(true);
                const { data } = await api.post("/invoice", {
                  reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                  status: "DUE",
                  source: "EXTERNAL",
                  file: file.target.value,
                });
                setLoading(false);

                toast.success(t("toast.sucess.created"));

                const test = setTransaction({
                  ...transaction,
                  Invoices: [data, ...transaction.Invoices],
                });
                getInvoices();
              }}
              name={transaction._id}
              folder="invoiceTransaction"
              width="w-full"
            />
            {loading && <Loader size="small" />}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {transaction.Invoices?.map((invoiceAssociated, index) => {
            return (
              <div key={invoiceAssociated._id} className="border rounded-lg p-4 relative">
                <div className="flex justify-between items-start mb-2">
                  <a href={invoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                    {invoiceAssociated.file && invoiceAssociated.file.indexOf(".pdf") !== -1 ? <FaRegFile size={18} /> : <AiFillFileImage size={18} />}
                  </a>
                  <button type="button" onClick={() => removeInvoice(index)} className="text-gray-500 hover:text-red-500">
                    <MdDeleteOutline size={20} />
                  </button>
                </div>
                <a href={invoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
                  {invoiceAssociated.reference}
                </a>
                <div className="text-xs text-gray-500">total: {invoiceAssociated.totalTaxIncluded || "-"}</div>
                {invoiceAssociated.createdAt && <div className="text-xs text-gray-500">created on {new Date(invoiceAssociated.createdAt).toLocaleDateString()}</div>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const TableAssociatedPurchaseInvoices = ({ transaction, setTransaction }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [purchaseInvoices, setPurchaseInvoices] = useState([]);

  const getPurchaseInvoices = async () => {
    const { data } = await api.post("/purchase-invoice/search", { per_page: 1000 });
    setPurchaseInvoices(data.purchaseInvoices);
  };

  const removePurchaseInvoice = (index) => {
    setTransaction({
      ...transaction,
      PurchaseInvoices: [...transaction.PurchaseInvoices.slice(0, index), ...transaction.PurchaseInvoices.slice(index + 1)],
    });
  };

  useEffect(() => {
    getPurchaseInvoices();
  }, []);

  return (
    <div className="flex w-full">
      <div className="w-full align-middle overflow-visible  flex flex-col gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <Select
              options={purchaseInvoices}
              value={transaction?.PurchaseInvoices || []}
              onChange={(selectedOptions) => {
                setTransaction({
                  ...transaction,
                  PurchaseInvoices: selectedOptions,
                });
              }}
              multiple
              placeholder={t("choose_invoice")}
              getLabel={(purchaseInvoice) => {
                const amount = purchaseInvoice.amount;
                let reference = purchaseInvoice.reference;
                let supplierName = purchaseInvoice.Supplier?.companyName ?? t("not_defined");
                let date;
                if (purchaseInvoice.issueDate) {
                  date = new Date(purchaseInvoice.issueDate);
                }
                return (
                  <div className="flex w-full gap-1">
                    <span className="flex-1">
                      {reference} - {supplierName}
                    </span>
                    <span className="px-2 bg-blue-100">{amount} €</span>
                    {date ? <span className="px-2 bg-green-100">{date.toLocaleDateString()}</span> : null}
                  </div>
                );
              }}
              by={(a, b) => a._id === b._id}
            />
          </div>
          <div className="flex gap-3">
            <FileInput
              onChange={async (file) => {
                if (!file) return;
                setLoading(true);
                const { data } = await api.post("/purchase-invoice", {
                  reference: decodeURIComponent(file.target.value.split("/").pop().split(".").shift()),
                  file: file.target.value,
                });
                setLoading(false);

                toast.success(t("toast.sucess.created"));

                setTransaction({
                  ...transaction,
                  PurchaseInvoices: [data, ...transaction.PurchaseInvoices],
                });
                getPurchaseInvoices();
              }}
              name={transaction._id}
              folder="purchaseInvoiceTransaction"
              width="w-full"
            />
            {loading && <Loader size="small" />}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {transaction.PurchaseInvoices?.map((purchaseInvoiceAssociated, index) => {
            return (
              <div key={purchaseInvoiceAssociated._id} className="border rounded-lg p-4 relative">
                <div className="flex justify-between items-start mb-2">
                  <a href={purchaseInvoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="text-gray-500">
                    {purchaseInvoiceAssociated.file && purchaseInvoiceAssociated.file.indexOf(".pdf") !== -1 ? <FaRegFile size={18} /> : <AiFillFileImage size={18} />}
                  </a>
                  <button type="button" onClick={() => removePurchaseInvoice(index)} className="text-gray-500 hover:text-red-500">
                    <MdDeleteOutline size={20} />
                  </button>
                </div>
                <a href={purchaseInvoiceAssociated.file} target="_blank" rel="noopener noreferrer" className="font-bold hover:underline">
                  {purchaseInvoiceAssociated.reference}
                </a>
                <div className="text-xs text-gray-500">Supplier: {purchaseInvoiceAssociated.Supplier?.companyName ?? t("not_defined")}</div>
                <div className="text-xs text-gray-500">total: {purchaseInvoiceAssociated.amount}</div>
                {purchaseInvoiceAssociated.issueDate && <div className="text-xs text-gray-500">issued on {new Date(purchaseInvoiceAssociated.issueDate).toLocaleDateString()}</div>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AccountOption = ({ account }) => {
  if (!account) return;

  return (
    <span className="flex items-center">
      <AccountIcon account={account} />
      <span className="ml-2">{getAccountName(account)}</span>
      <span className="ml-1">
        {account.amount} {currencyToStr(account.currency)}
      </span>
    </span>
  );
};

const AccountIcon = ({ account }) => {
  if (account.details === "petty_cash") return <FaCashRegister className="h-5 w-5" />;
  if (account.details === "savings") return <FaMoneyBill className="h-5 w-5" />;
  return <img src={account?.Requisition?.NordigenInstitution?.logo} className="h-5 w-5" />;
};

export default EditTransactionModal;
