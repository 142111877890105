import categoriesDetails from "@data/categories.js";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import api from "@services/api";
import { Listbox } from "@headlessui/react";
import Loader from "../../components/utils/Loader";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

const years = Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => new Date().getFullYear() - i);

const Ledger = () => {
  const { t } = useTranslation();
  const [transactionsBycategory, setTransactionsBycategory] = useState({});
  const [currency, setCurrency] = useState(null);
  const [filter, setFilter] = useState({
    groupBy: "category",
    year: new Date().getFullYear(),
    orderBy: "date",
    order: "ASC",
  });
  const [loading, setLoading] = useState(false);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!filter || !organization) return;
    getTransactions();
  }, [filter, organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_analytics_ledger);
  }, []);

  const getTransactions = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/transaction/search", {
        OrganizationId: organization._id,
        ...filter,
      });
      if (!ok) throw new Error(t("toast.error.went_wrong"));
      // match the category id with the category name
      const transactionsBycategory = {};
      for (const [key, value] of Object.entries(data.transactions)) {
        const category = categoriesDetails.find((category) => category.id === key);
        if (category) transactionsBycategory[category.name] = value;
      }
      setTransactionsBycategory(transactionsBycategory);
      setCurrency(data.currency);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  const Category = ({ title, data, currency }) => {
    const [open, setOpen] = useState(true);

    return (
      <div className="w-full overflow-x-auto mb-4 bg-white border-gray-200" onClick={() => setOpen((p) => !p)}>
        <div className="px-2 py-1 text-sm text-gray-900 bg-gray-300">{title}</div>
        <table className="w-full table-fixed">
          <thead className="bg-gray-50 cursor-pointer border">
            <tr>
              <th className="w-2/12 px-2 py-2 text-sm text-gray-900 border-r">{t("date")}</th>
              <th className="w-8/12 px-2 py-2 text-sm text-gray-900 border-r">{t("description")}</th>
              <th className="w-2/12 px-2 py-2 text-sm text-gray-900 text-end">{`${t("amount")} (${currency})`}</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {open &&
              data.transactions.map((transaction) => (
                <tr key={transaction._id} className="border">
                  <td className="px-4 py-1 text-sm text-gray-900 border-r">{new Date(transaction.date).toLocaleDateString()}</td>
                  <td className="px-4 py-1 text-sm text-gray-900 border-r">{transaction.name}</td>
                  <td>
                    <Amount amount={transaction.amount} />
                  </td>
                </tr>
              ))}
            <tr className="border-r">
              <td />
              <td className="px-2 py-2 text-sm font-bold text-gray-900 border-r text-end">{t("total")}:</td>
              <td className="w-min px-2 py-2 text-sm text-gray-900 text-end border-b">{parseFloat(data?.total).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-6 items-start text-left text-black-100 bg-white p-4 rounded-lg ">
      <div className="font-semibold items-center w-full">
        <div className="flex gap-2 items-center text-sm  text-black-90">
          <div className="flex gap-2 items-center font-semibold">
            <span>{t("ledger.year")}</span>
          </div>
          <Listbox value={filter?.year} onChange={(value) => setFilter({ ...filter, year: value })}>
            <div className="relative">
              <div className="w-full flex gap-4 py-2 pl-3 pr-8 items-center justify-between bg-white border border-gray-300 rounded-md h-10 overflow-hidden hover:border-sky-700">
                <Listbox.Button>{filter?.year}</Listbox.Button>
                <Listbox.Options className={`absolute z-20  my-1 list-none top-0 max-h-64 overflow-y-auto border border-gray-300 rounded-md bg-white divide-y divide-gray-200`}>
                  {years.map((year) => (
                    <Listbox.Option className="py-2 pl-3 pr-8 hover:bg-gray-200" key={year} value={year}>
                      {year}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </div>
          </Listbox>
        </div>
      </div>
      <div className="w-full">
        {Object.entries(transactionsBycategory).map(([title, data]) => (
          <Category title={title} data={data} currency={currency} />
        ))}
      </div>
    </div>
  );
};

const Amount = ({ amount }) => {
  let color = amount > 0 ? "text-green-700" : "text-red-700";
  return <div className={`px-2 py-1 rounded-sm flex justify-end items-center text-sm font-bold ${color}`}>{amount}</div>;
};

export default Ledger;
