import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiSelector, HiCheck } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";

import { setOrganization } from "../../redux/auth/actions";
import api from "@services/api";

const Organizations = () => {
  const [organizations, setOrganizations] = useState();

  const { organization } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const { data } = await api.post("/organization/search", { per_page: 500 });
      setOrganizations(data);
    })();
  }, []);

  useEffect(() => {
    if (!organizations) return;

    const organizationIdLocalStorage = localStorage.getItem("organization_id");
    if (organizationIdLocalStorage) {
      const organizationLocalStorage = organizations.find((t) => t._id === organizationIdLocalStorage);
      if (organizationLocalStorage) handleOrganization(organizationLocalStorage);
      else handleOrganization(organizations[0]);
    } else {
      handleOrganization(organizations[0]);
    }
  }, [organizations]);

  const handleOrganization = (organization) => {
    dispatch(setOrganization(organization));
    localStorage.setItem("organization_id", organization._id);
  };

  if (!organizations || !organization) return <></>;

  if (organizations.length === 1)
    return (
      <div className="flex items-center px-3 py-2 text-sm font-medium gap-2 w-full mt-1">
        {organization.logo ? (
          <img src={organization.logo} alt={organization.name} className="h-6 w-6 rounded" />
        ) : (
          <span className="h-6 w-6 flex items-center justify-center rounded bg-blue-500 text-white font-bold">{organization.name.charAt(0)}</span>
        )}
        <span className="hidden sm:block">{organization.name}</span>
      </div>
    );

  return (
    <div className="relative w-full">
      <Listbox value={organization} onChange={handleOrganization}>
        <div className="relative mt-1">
          <Listbox.Button className="flex items-center space-x-2 px-3 py-2 text-sm font-medium text-light-grey cursor-pointer">
            {organization.logo ? (
              <img src={organization.logo} alt={organization.name} className="h-6 w-6 rounded" />
            ) : (
              <span className="h-6 w-6 flex items-center justify-center rounded bg-blue-500 text-white font-bold">{organization.name.charAt(0)}</span>
            )}
            <span className="hidden sm:block">{organization.name}</span>
            <HiSelector />
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute top-full left-3 z-10 w-64 max-h-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:text-sm">
              {organizations
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((organization) => (
                  <Listbox.Option
                    as="div"
                    key={organization._id}
                    className={({ active, selected }) => `relative select-none py-2 px-3 text-gray-900 ${active && "bg-gray-100"}`}
                    value={organization}>
                    {({ selected }) => (
                      <div className="cursor-pointer flex items-center justify-between space-x-2">
                        <div className="flex items-center space-x-2 pr-8">
                          {organization.logo ? (
                            <img src={organization.logo} alt={organization.name} className="h-6 w-6 rounded" />
                          ) : (
                            <span className="h-6 w-6 flex items-center justify-center rounded bg-blue-500 text-white font-bold">{organization.name.charAt(0)}</span>
                          )}
                          <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{organization.name}</span>
                        </div>
                        {selected ? <HiCheck className="h-5 w-5 text-blue-700 ml-auto" aria-hidden="true" /> : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Organizations;
