import { useState, useEffect } from "react";
import api from "@services/api";
import Loader from "@components/utils/Loader";
import { Paginator } from "@components/utils/Pagination";
import { SearchBar } from "@components/utils/SearchBar";

import { currencyToStr } from "@utils";

import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { useSelector } from "react-redux";

const List = () => {
  const { t } = useTranslation();
  const [transactions, setTransaction] = useState([]);
  const [filter, setFilter] = useState({ per_page: 10, page: 1, search: "" });
  const [total, setTotal] = useState(0);
  const { organization } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (!organization) return;
    getStripeTransactions();
  }, [filter, organization]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS["page_view_transactions-stripe"]);
  }, []);

  async function getStripeTransactions() {
    const { data } = await api.post("/stripe/transaction/search", {
      per_page: filter.per_page,
      page: filter.page,
      search: filter.search,
      OrganizationId: organization._id,
    });
    setTransaction(data.transactions);
    setTotal(data.total);
  }

  if (!transactions) return <Loader />;

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="text-2xl font-bold mb-5"> {t("stripe_transactions")} </div>
          <SearchBar search={filter.search} setFilter={setFilter} />
          <Table transactions={transactions} />
          <div className="flex flex-row items-center justify-center mt-4">
            <Paginator page={filter.page} setPage={(p) => setFilter({ ...filter, page: p })} last={Math.ceil(total / filter.per_page)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Table = ({ transactions }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-auto mt-8 shadow ring-1 ring-black ring-opacity-5 rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-3 py-3.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">{t("date")}</th>
            <th className="px-3 py-3.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">{t("name")}</th>
            <th className="px-3 py-3.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">{t("client")}</th>
            <th className="px-3 py-3.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">{t("amount")}</th>
            <th className="px-3 py-3.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">{t("vat")}</th>
            <th className="px-3 py-3.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">{t("status")}</th>
            <th className="px-3 py-3.5 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">{t("type")}</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {transactions.map((transaction) => (
            <tr key={transaction._id} className="hover:bg-gray-50 transition-colors duration-200">
              <td className="px-3 py-3 text-sm text-gray-900 whitespace-nowrap">{new Date(transaction.created).toLocaleDateString()}</td>
              <td className="px-3 py-3 text-sm text-gray-900 max-w-xs truncate">{transaction.description}</td>
              <td className="px-3 py-3 text-sm text-gray-900 whitespace-nowrap">{transaction.Client?.companyName || "-"}</td>
              <td className="px-3 py-3 text-sm whitespace-nowrap">
                <Amount amount={transaction.amount} currency={currencyToStr(transaction.currency)} />
              </td>
              <td className="px-3 py-3 text-sm whitespace-nowrap">{transaction.Tax?.name || "-"}</td>
              <td className="px-3 py-3 text-sm whitespace-nowrap">
                <Status status={transaction.status} />
              </td>
              <td className="px-3 py-3 text-sm text-gray-900 capitalize whitespace-nowrap">{transaction.type ? t(`stripe_transaction.status.${transaction.type}`) : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Amount = ({ amount, currency }) => {
  const color = amount > 0 ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700";
  return (
    <span className={`px-2 py-1 rounded text-sm font-medium ${color}`}>
      {amount}
      {currency}
    </span>
  );
};

const Status = ({ status }) => {
  const { t } = useTranslation();

  if (!status) return "-";

  return <span className={"px-2 py-1 rounded text-sm font-medium"}>{t(`transactionStripe.status_${status}`)}</span>;
};

export default List;
