import { useEffect, useState } from "react";
import queryString from "query-string";
import api from "@services/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const ConfirmationCheckout = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("complete");
  const params = queryString.parse(location.search);
  const { checkout_session_id } = params;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!checkout_session_id) return;
      try {
        const { ok, data } = await api.get(`/stripe/session/${checkout_session_id}`);
        if (!ok) return setStatus("error");

        // todo : redierction vers la page d'accueil et aficher un toaster
        switch (data.status) {
          case "complete":
            toast.success("Paiement réussi");
            break;
          case "expired":
            toast.error("Paiement expiré");
            break;
          case "error":
            toast.error("Une erreur s'est produite");
            break;
          default:
            toast.error("Erreur serveur");
            break;
        }

        navigate("/");
      } catch (e) {
        toast.error("erreur serveur");
        console.log(e);
      }
    })();
  }, [checkout_session_id]);

  return (
    <div className="h-full bg-black-20 w-full flex flex-col items-center justify-center">
      <span className="italic">Traitement en cours...</span>
      <div className="mt-2 mb-5 border-l-primary animate-spin inline-block w-16 h-16 border-[6px] rounded-full" role="status" />
      <span className="italic text-xs">Merci de ne pas fermer cette page pendant le traitement de votre demande...</span>
    </div>
  );
};

export default ConfirmationCheckout;
