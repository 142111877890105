import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/utils/Loader";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setOrganization } from "../../redux/auth/actions";

const Callback = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.Auth.organization);
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const state = queryParams.get("state");

  const redirectGocardless = async () => {
    try {
      const res = await api.get(`/gocardless/access-token/${code}`);

      if (!res.ok) throw new Error(`Error retrieving access token`);
      const response = await api.put(`/organization/${organization._id}`, {
        gocardlessAccessToken: res.data.access_token,
        gocardlessOrganisationId: res.data.organisation_id,
        gocardlessRaw: res.data,
      });

      if (!response.ok) throw new Error(`Error updating organisation`);
      dispatch(setOrganization(response.data));
      console.log("state", state);
      if (!state) return navigate("/");
      if (state === "/settings/my-organizations") return navigate("/settings/my-organizations", { state: { scrollTo: "gocardless" } });
      navigate(`${state}`);
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };

  useEffect(() => {
    if (!code) throw new Error(`No code specified`);
    redirectGocardless();
  }, [location]);

  return <Loader />;
};

export default Callback;
